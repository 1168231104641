import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupApiService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  GetListGroup(page: number, size: number) {
    return this.http.get<any>(this.baseUrl + `api/group/get?page=${page}&size=${size}`);
  }

  GetGroup(groupId) {
    return this.http.get<any>(this.baseUrl + 'api/group/detail/' + groupId);
  }

  DeleteGroup(groupId) {
    return this.http.delete<any>(this.baseUrl + 'api/group/delete/' + groupId);
  }

  GetCreateGroup(groupId: string) {
    var url = groupId ? `api/group/edit/${groupId}`: 'api/group/create';
    return this.http.get<any>(this.baseUrl + url);
  }

  CreateNewGroup(data: any) {
    return this.http.post<any>(this.baseUrl + 'api/group/create', data);
  }

  EditGroup(groupId: string, data: any) {
    return this.http.put<any>(this.baseUrl + 'api/group/edit/' + groupId, data);
  }

  GetMyGroup() {
    return this.http.get<any>(this.baseUrl + 'api/group/my');
  }

  GetAllGroup() {
    return this.http.get<any>(this.baseUrl + 'api/group/all');
  }
}
