import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../_services/loader.service';
import { DeviceService } from '../../../_services/device.service';
import { NotificationService } from '../../../_services/notification.service';
import { N_Notification } from '../../../_models/Notification';
import { MatDialog } from '@angular/material';
import { NoticeDetailDialog } from './notice-detail/notice-detail.component';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  offsetId = 0
  sort: string = "1"
  size: number = 5
  progress = false
  isRemain = true
  scrollContainer: HTMLElement

  isMobile: boolean
  notifications: N_Notification[]
  detail: N_Notification

  constructor(
    private loader: LoaderService,
    private apiService: NotificationService,
    private dialog: MatDialog,
    private topNav: TopNavService,
    private device: DeviceService,
  ) {
    
    topNav.centerDisplay.next({ text: 'お知らせ' })
  }

  ngOnInit() {
    this.device.IsMobile.subscribe(value => {
      this.isMobile = value
      this.scrollContainer = this.isMobile ? document.body : document.getElementById('scrollContainer')
    })
    this.Initialize()
  }

  async Initialize() {
    this.offsetId = 0
    var result = await this.loader.Forward(this.apiService.GetNotifications(this.sort, this.size, this.offsetId), true, true).toPromise()
    if (result.status === 200) {
      this.notifications = result.data
      this.isRemain = result.data.length > 0 && result.data.length >= this.size
      this.offsetId = result['offsetId']
    }
  }
  Detail(n: N_Notification) {
    this.detail = n
    if (this.isMobile && n.content) {
      this.detail = n
      this.dialog.open(NoticeDetailDialog, {
        panelClass: ['no-pad-dialog', 'sp'],
        data: this.detail
      })
    }
    if (!n.isRead) {
      this.apiService.ReadNotification(this.detail.notificationId).subscribe(result => {
        if (result.status === 200) {
          this.notifications.find(n => n.notificationId == result.data.readNotificationId).isRead = true
          this.topNav.unreadMessage.next(result.data.unreadMessage)
        }
      })
    }
  }

  ChangeSort() {
    this.Initialize()
  }

  LoadNotifications() {
    if (this.isRemain && !this.progress) {
      this.progress = true
      this.apiService.GetNotifications(this.sort, this.size, this.offsetId).subscribe(result => {
        if (result.status === 200) {
          this.offsetId = result.offsetId
          this.notifications = this.notifications.concat(result.data)
          this.isRemain = result.data.length > 0 && result.data.length >= this.size
          this.progress = false
        }
      })
    }
  }
}
