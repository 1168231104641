import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../../_services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-file-auth',
  templateUrl: './file-auth.component.html',
  styleUrls: ['./file-auth.component.css']
})
export class FileAuthPopup implements OnInit {
  filePass: string = '';
  titlePopup: string;
  messageConfirm: string;
  isUpdatePass: boolean;
  filePassConfirm: string = '';
  isErr: boolean;
  messageErr: string = 'パスワードを入力してください。';
  messageConfirmErr: string = 'パスワード再入力。';
  form: FormGroup;
  formSubmitAttempt: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private typePopup: number,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FileAuthPopup>,
    private authService: AuthService,
  ) {
    this.isUpdatePass = false;
    this.isErr = false;
    this.setTitlePopup();
    this.form = this.fb.group({
      password: [null, Validators.required, Validators.maxLength, Validators.min(1)],
      newPassword: [null, Validators.required, Validators.maxLength, Validators.min(1)]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.filePass.trim().length > 0) {
      if (/^[a-zA-Z0-9]*$/.test(this.filePass.trim()) == false) {
        this.isErr = true;
        this.messageErr = 'パスワードに不正な文字が含まれています。';
      }
      else {
        if (this.typePopup == 1) {
          if (this.filePass == this.filePassConfirm) {
            this.closePopup(this.filePass);
          }
          else {
            this.isErr = true;
            this.messageErr = 'パスワードが一致していません。パスワードとパスワード（確認用）に同じパスワードを設定してください';
          }
        }
        else if (this.typePopup == 2) {
          const pass = this.authService.GetPassViewFile();
          if (this.filePass != pass) {
            this.isErr = true;
            this.messageErr = 'パスワードが正しくありません。';
          }
          else {
            this.closePopup('SUCCESS');
          }
        }
        else {
          if (this.filePass == this.filePassConfirm) {
            this.closePopup(this.filePass);
          }
          else {
            this.isErr = true;
            this.messageErr = 'パスワードが一致していません。パスワードとパスワード（確認用）に同じパスワードを設定してください';
          }
        }
      }
    }
    else {
      this.isErr = true;
      this.messageErr = 'パスワードを入力してください。';
    }
  }

  setTitlePopup() {
    if (this.typePopup == 1) {
      // Registor
      this.titlePopup = '承認パスワード作成';
      this.isUpdatePass = true;
    }
    else if (this.typePopup == 2) {
      // Input pass view file
      this.titlePopup = '承認パスワード';
    }
    else if (this.typePopup == 3) {
      // update pass
      this.titlePopup = '承認パスワード更新';
      this.isUpdatePass = true;
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
      || (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  closePopup(pass: string) {
    this.dialogRef.close(pass);
  }

  onTextChange(textValue: string, control: string): void {
    if (control == 'filePass') {
      this.form.patchValue({ password: textValue.replace(' ', '') });
    }
    else {
      this.form.patchValue({ newPassword: textValue.replace(' ', '') });
    }
  }
};
