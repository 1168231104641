import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { DailyReportService } from '../../../../_services/daily-report.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DailyReport } from '../../../../_models/DailyReport';

@Component({
  selector: 'app-daily-create',
  templateUrl: './daily-create.component.html',
  styleUrls: ['./daily-create.component.css'],
})
export class DailyCreateDialog implements OnInit {

  id: number
  reportDate: Date
  form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<DailyCreateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DailyReport,
    fb: FormBuilder,
    private ngZone: NgZone,
    private apiService: DailyReportService,
    private loader: LoaderService
  ) {
    dialogRef.disableClose = true
    this.reportDate = data.date
    this.id = data.report && data.report.id

    this.form = fb.group({
      plan: [data.report && data.report.reportContent.plan, [Validators.required, Validators.maxLength(400)]],
      actual: [data.report && data.report.reportContent.actual, [Validators.required, Validators.maxLength(400)]],
      businessTrip: [data.report && data.report.reportContent.businessTrip, [Validators.maxLength(80)]],
      memo: [data.report && data.report.reportContent.memo, Validators.maxLength(80)],
    })
  }

  ngOnInit() {
  }

  @ViewChild('cfcAutosize', { static: true })
  contentFCAutosize: CdkTextareaAutosize;

  resizeTextArea() {
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.contentFCAutosize.resizeToFitContent(true));
  }

  OnSave() {
    var data = {
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.SaveReport(data, this.id, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }

  OnSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return;
    var data = {
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.SubmitReport(data, this.id, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }

  OnReSubmit() {
    if (!this.form.valid) return;
    var data = {
      id: this.id,
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.ReSubmitReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }
}
