import { Component, OnInit } from '@angular/core';
import { GroupApiService } from 'src/app/_services/group.api.service'
import { Group } from 'src/app/_models/Group'
import { MatTableDataSource, PageEvent, MatDialog } from '@angular/material';
import { LoaderService } from 'src/app/_services/loader.service';
import { DeleteDialog } from './delete-dialog/delete-dialog.component';
import { TopNavService } from '../../../_services/route.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  ScreenName: string = "グループ一覧"
  Groups: Group[] = []

  columnIds = ['id', 'name', 'description', 'managers', 'createUser', 'createDate', 'link'];
  dataSource: MatTableDataSource<Group>

  pageLength: number
  pageSize: number
  pageIndex: number
  pageSizeOptions = [25, 50, 100, 200]

  constructor(
    private topNav: TopNavService,
    private router: Router,
    private apiService: GroupApiService,
    private loader: LoaderService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.pageIndex = 0
    this.pageSize = this.pageSizeOptions[0]
    this.GetListGroup()
  }

  async GetListGroup() {
    var result = await this.loader.Forward(this.apiService.GetListGroup(this.pageIndex + 1, this.pageSize), true, true).toPromise();
    if (result.status === 200) {
      this.Groups = result.data.groups;
      this.pageLength = result.data.count
      this.dataSource = new MatTableDataSource<Group>(this.Groups);
    }
  }

  ChangePage(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex = 0
    } else {
      this.pageIndex = event.pageIndex
    }
    this.pageSize = event.pageSize
    this.loader.Forward(this.apiService.GetListGroup(this.pageIndex + 1, this.pageSize)).subscribe(result => {
      if (result.status === 200) {
        this.Groups = result.data.groups
        this.pageLength = result.data.count
        this.dataSource = new MatTableDataSource<Group>(this.Groups)
      }
    })
  }

  DeleteGroup(group: Group) {
    var dialogRef = this.dialog.open(DeleteDialog, {
      data: group
    })
    dialogRef.afterClosed().subscribe(reload => {
      if (reload) {
        this.loader.Forward(this.apiService.GetListGroup(this.pageIndex + 1, this.pageSize)).subscribe(result => {
          if (result.status === 200) {
            this.Groups = result.data.groups
            this.pageLength = result.data.count
            this.dataSource = new MatTableDataSource<Group>(this.Groups)
          }
        })
      }
    })
  }

  applyFilter(s) {
    this.dataSource.filter = s.trim().toLowerCase();
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
