import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import { UserWeeklyReport } from '../../../../_models/WeeklyReport';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from '../../../../_services/loader.service';
import { WeeklyReportService } from '../../../../_services/weekly-report.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-review-weekly',
  templateUrl: './review-weekly.component.html',
  styleUrls: ['./review-weekly.component.css']
})
export class ReviewWeeklyDialog implements OnInit {
  report: UserWeeklyReport
  form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<ReviewWeeklyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    fb: FormBuilder,
    private loader: LoaderService,
    private apiService: WeeklyReportService,
    private ngZone: NgZone,
  ) {
    this.form = fb.group({
      reviewComment: ['', [Validators.maxLength(400)]]
    })
  }

  ngOnInit() {
    this.Initialize()
  }

  Initialize() {
    this.loader.Forward(this.apiService.GetDetail(this.data.reportId)).subscribe(result => {
      if (result.status === 200) {
        this.report = result.data
        this.report && this.report.reportContent.workDetails.map(x => x.date = new Date(x.dateValue.year, x.dateValue.month - 1, x.dateValue.day))
        this.form.controls.reviewComment.patchValue(result.data.reviewComment)
      }
    })
  }

  OnApprove() {
    if (!this.form.valid) return;
    var data = {
      id: this.data.reportId,
      reviewComment: this.form.value.reviewComment,
    }
    this.loader.Forward(this.apiService.ApproveReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    })
  }

  OnReject() {
    if (!this.form.valid) return;
    var data = {
      id: this.data.reportId,
      reviewComment: this.form.value.reviewComment,
    }
    this.loader.Forward(this.apiService.RejectReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    })
  }

  Export() {
    this.apiService.ExportReport(this.data.reportId).subscribe(result => {
      var fileName = `週報_${this.report.reportContent.fullName}_${this.report.reportDateValue.year}-${this.report.reportDateValue.month}-${this.report.reportDateValue.day}.xlsx`
      saveAs(result, fileName)
    })
  }

  @ViewChild('cfcAutosize', { static: true })
  contentFCAutosize: CdkTextareaAutosize;

  resizeTextArea() {
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.contentFCAutosize.resizeToFitContent(true));
  }
}

interface DialogData {
  reportId: number,
}
