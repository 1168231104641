import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ja';
import { LoaderService } from '../../../_services/loader.service';
import { GroupApiService } from '../../../_services/group.api.service';
import { Group } from '../../../_models/Group';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { NotificationService } from '../../../_services/notification.service';
import { Category } from '../../../_models/Notification';
import { NavigationEnd, Router } from '@angular/router';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-create-notify',
  templateUrl: './create-notify.component.html',
  styleUrls: ['./create-notify.component.css']
})
export class CreateNotifyComponent implements OnInit {
  Editor = ClassicEditor
  config = {
    language: 'ja'
  }

  ScreenName = 'お知らせ'
  taishou
  groups: Group[]
  categories: Category[]
  form: FormGroup

  groupControl = new FormControl('', [Validators.required])

  constructor(
    private topNav: TopNavService,
    private router: Router,
    private loader: LoaderService,
    private groupApiService: GroupApiService,
    private notifyService: NotificationService,
    private snackBar: MatSnackBar,
    fb: FormBuilder,
  ) {
    this.form = fb.group({
      title: ['', [Validators.required, Validators.maxLength(80)]],
      summary: ['', [Validators.required]],
      category: ['', [Validators.required]],
      content: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.groupApiService.GetAllGroup().subscribe(result => {
      if (result.status === 200) {
        this.groups = result.data
      }
    })
    this.notifyService.GetCategories().subscribe(result => {
      if (result.status === 200) {
        this.categories = result.data
      }
    })
  }
  SendNotify() {
    this.form.markAllAsTouched()
    if (!this.taishou) {
      this.snackBar.open('対象を選択してください', null, { duration: 5000 })
      return
    }
    if (this.taishou === '3' && !this.groupControl.value) {
      this.groupControl.markAsTouched()
      this.snackBar.open('必須項目を確認してください', null, { duration: 5000 })
      return
    }
    if (!this.form.valid) return

    var data = {
      taishou: this.taishou,
      groups: this.groupControl.value,
      title: this.form.controls.title.value,
      summary: this.form.controls.summary.value,
      categoryId: this.form.controls.category.value,
      content: this.form.controls.content.value,
    }
    this.loader.Forward(this.notifyService.SendNotify(data)).subscribe()
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
