import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LoaderService } from 'src/app/_services/loader.service';
import { UserApiService } from '../../../_services/user.api.service';
import { User } from '../../../_models/User';
import { MyGroup } from '../../../_models/MyGroup';
import { AuthService } from '../../../_services/auth.service';
import { TopNavService } from '../../../_services/route.service';

export interface Companys {
  code: string
  name: string
}

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css'],
})
export class UserUpdateComponent implements OnInit {

  ScreenName: string = '';
  @ViewChild('empCode', { static: false }) inputEmp: ElementRef;
  User: User
  Groups: MyGroup[]
  userId: string
  allowSubmit: boolean = false;
  companys: Companys[]

  form: FormGroup

  constructor(
    private topNav: TopNavService,
    private apiService: UserApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AuthService,
    private loader: LoaderService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      companyCode: ['', [Validators.required]],
      employeeCode: ['', [Validators.required, Validators.maxLength]],
      email: ['', [Validators.email]]
    })
    let statusupdate = this.accountService.getStatusUpdateUser();
    if (statusupdate) {
      this.accountService.setStatusUpdateUser('0');
      this.router.navigateByUrl('/admin/user');
    }
    else {
      this.setCompany();
      this.topNav.dataTransfer.subscribe(value => { this.userId = value });
      this.GetUserInfo();
    }
  }

  setCompany() {
    this.companys = [
      { code: 'COM01', name: 'アワードシステム' },
      { code: 'COM02', name: 'サンライトシステムサービス' }
    ];
  }

  onTextChange(textValue: string, control: string): void {
    if (control == 'empCode') {
      this.form.patchValue({ employeeCode: textValue.replace(' ', '') });
    }
    else {
      this.form.patchValue({ companyCode: textValue.replace(' ', '') });
    }
  }

  async GetUserInfo() {
    var result = await this.loader.Forward(this.apiService.GetUser(this.userId), true, true).toPromise();
    if (result.status === 200) {
      this.User = result.data.user
      this.Groups = result.data.groups
      this.ScreenName = this.User.name
      if (result.data.user) {
        this.form.patchValue({
          companyCode: result.data.user.companyCode,
          employeeCode: result.data.user.employeeCode,
          email: result.data.user.email
        });
      }
    }
  }

  async OnSubmit() {
    if (!this.form.valid) {
      //this.snackbar.open('必須項目をもう一度ご確認ください。', '', { duration: 5000 });
      return false;
    }
    else {
      this.User.email = this.form.controls.email.value;
      this.User.employeeCode = this.form.controls.employeeCode.value;
      this.User.companyCode = this.form.controls.companyCode.value;
      this.User.id = parseInt(this.userId);
      var result = await this.apiService.UpdateUser(this.User).toPromise()
      if (result.status === 200) {
        this.accountService.setStatusUpdateUser('1');
        this.router.navigateByUrl('/admin/user/' + this.userId);
      }
      else {
        this.accountService.setStatusUpdateUser('0');
        alert(result.data)
      }
    }
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}


