import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '../../../_models/User';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '../../../_services/loader.service';
import { FileAuthPopup } from '../../common/popups/file-auth/file-auth.component';
import { TopNavService } from '../../../_services/route.service';
import { DeviceService } from '../../../_services/device.service';
import { MatSnackBar } from '@angular/material';
import { CategoriesAction } from '../../../_models/CategoriesAction';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-salary-bonus',
  templateUrl: './salary-bonus.component.html',
  styleUrls: ['./salary-bonus.component.css']
})
export class SalaryBonusComponent implements OnInit {
  form: FormGroup;
  isAdmin: boolean;
  public Loading = new BehaviorSubject<boolean>(false);
  passViewFile: string;
  passViewFileTemp: string;
  User: User;
  linkNavigation: string = 'salary';
  isMobile: boolean;
  countSalary: number = 0;
  countBonus: number = 0;
  countWith: number = 0;
  arrCategories: CategoriesAction[] = [];

  constructor(
    private loader: LoaderService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private topNav: TopNavService,
    private snackBar: MatSnackBar,
    private accountService: AuthService,
    device: DeviceService
  ) {
    device.IsMobile.subscribe(value => this.isMobile = value)
    topNav.centerDisplay.next({ text: ' 給与・賞与 ' })
  }

  ngOnInit() {
    if (this.arrCategories.length == 0) {
      this.initCategories();
    }
    this.getConfig();
    this.checkRegistorAuth();
  }

  ngOnDestroy() {
    this.topNav.Reset()
  }

  chooseAction(typeSelect: number) {
    if (typeSelect == 2) {
      this.linkNavigation = 'bonus';
    }
    else if (typeSelect == 5 || typeSelect == 6) {
      this.linkNavigation = 'withholding';
    }
    this.router.navigate(['files'], { queryParams: { files: this.linkNavigation } });
  }

  async checkRegistorAuth() {
    this.passViewFile = this.authService.GetPassViewFile();
    if (this.isHasData(1)) {
      this.openPopup(2);
    }
    else {
      this.openPopup(1);
    }
  }

  initCategories() {
    this.loader.Loading.next(true);
    try {
      this.accountService.getCategories().subscribe(result => {
        if (result.status == 200 && result.data.length > 0) {
          this.arrCategories = result.data;
          if (result.data.filter(c => [5, 6].includes(c.categoriesID)).length === 2) {
            this.arrCategories = result.data.filter(c => c.categoriesID !== 6);
          }

          this.arrCategories.forEach(function (v, i) {
            if (v.categoriesID == 1) {
              v.categoryName = "給与明細";
            } else if (v.categoriesID == 2) {
              v.categoryName = "賞与明細";
            }
          })
        }
        this.loader.Loading.next(false);
      });
    } catch (e) {
      console.log(e);
      this.loader.Loading.next(false);
    }
  }

  /**
   * function insert-update password view file
   * @param typePopup
   * typePopup == 1 -> insert password
   * typePopup == 3 -> update password
   */
  async updatePassViewFile(typePopup: number) {
    this.loader.Loading.next(true);
    this.authService.ChangePasswordViewFile(this.passViewFileTemp).subscribe(result => {
      this.loader.Loading.next(false);
      if (result) {
        this.passViewFile = this.authService.GetPassViewFile();
        if (typePopup == 3) {
          this.snackBar.open('ファイルを閲覧するパスワードを更新しました。', null, { duration: 3000 })
        }
      }
    });
  }

  /**
   * function open popup input password
   * @param typeOpen
   * typeOpen == 1 -> registor password
   * typeOpen == 2 -> input password
   * typeOpen == 3 -> update password
   */
  openPopup(typeOpen: number) {
    const dialogRef = this.dialog.open(FileAuthPopup, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
      width: this.isMobile ? '100vw' : '350px',
      minWidth: this.isMobile ? '100vw' : 'auto',
      data: typeOpen
    });
    dialogRef.afterClosed().subscribe(result => {
      this.passViewFileTemp = result;
      if (this.isHasData(0)) {
        if (result != 'SUCCESS') {
          this.updatePassViewFile(typeOpen);
        }
      }
      else {
        if (typeOpen == 1 || typeOpen == 2) {
          this.router.navigateByUrl('/notification');
        }
      }
    });
  }

  getConfig() {
    try {
      this.loader.Loading.next(true);
      this.authService.countFileUnRead().subscribe(value => {
        if (value != undefined && value != null) {
          this.countSalary = value.item1;
          this.countBonus = value.item2;
          this.countWith = value.item3;
        }
        this.loader.Loading.next(false);
      });
    } catch (e) {
      console.log(e);
      this.loader.Loading.next(false);
    }
  }

  /**
   * function check properties is has data
   * @param type
   * type == 1 -> check has data password view file in database.
   * type != 1 -> check has data variable save password temple.
   * return true if has data.
   */
  isHasData(type: number) {
    if (type == 1) {
      this.passViewFileTemp = this.passViewFile;
    }
    if (this.passViewFileTemp) {
      return true;
    }
    else {
      return false;
    }
  }
}
