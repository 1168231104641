import { Component, OnInit, Input } from '@angular/core';
import { WeeklyReportService } from '../../../_services/weekly-report.service';
import { LoaderService } from '../../../_services/loader.service';
import { MatDialog, MatCalendarCellCssClasses } from '@angular/material';
import { ClassProviderService } from '../../../_services/class-provider.service';
import { DeviceService } from '../../../_services/device.service';
import { UserWeeklyReport } from '../../../_models/WeeklyReport';
import { ReviewWeeklyDialog } from './review-weekly/review-weekly.component';

@Component({
  selector: 'app-weekly-group',
  templateUrl: './weekly-group.component.html',
  styleUrls: ['./weekly-group.component.css']
})
export class WeeklyGroupComponent implements OnInit {
  @Input() groupId: number
  reportDate: Date
  timeGetData: Date
  reports: { userId: number, name: string, report: UserWeeklyReport, isReminded: boolean }[]
  isMobile: boolean

  constructor(
    private apiService: WeeklyReportService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private clazz: ClassProviderService,
    device: DeviceService,
  ) {
    device.IsMobile.subscribe(value => this.isMobile = value)
  }

  ngOnInit() {
    this.Initialize()
  }

  async Initialize(requestDate?: Date, isLoading: boolean = false) {
    var result = await this.loader.Forward(this.apiService.GetGroupWeeklyReports(this.groupId, requestDate), isLoading, true).toPromise()
    if (result.status === 200) {
      this.reportDate = new Date(result.data.reportDate)
      this.reports = result.data.reports
    }
  }
  ChangeDate(offset: number) {
    //this.reportDate.setDate(this.reportDate.getDate() + offset)
    this.timeGetData = this.reportDate;
    this.timeGetData.setDate(this.reportDate.getDate() + offset);
    console.log(this.timeGetData);
    this.Initialize(this.timeGetData, true)
  }
  GoDetail(reportId: number) {
    var dialogRef = this.dialog.open(ReviewWeeklyDialog, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
      data: {
        reportId: reportId,
      },
      minWidth: this.isMobile ? '100vw' : '90vw'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isSaveSuccess) {
        this.Initialize(this.reportDate)
      }
    })
  }
  RequestReport(userId: number) {
    this.loader.Forward(this.apiService.RequestReport(userId, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.reports.find(r => r.userId === userId).isReminded = true
      }
    })
  }

  GetReportStatusClass(statusId: number) {
    return this.clazz.GetReportStatusClass(statusId)
  }

  dateClass = (d: Date): MatCalendarCellCssClasses => {
    return d.getDay() == 0 ? 'sunday' : d.getDay() == 6 ? 'saturday' : ''
  }
}
