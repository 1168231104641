import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { LoaderService } from '../../../_services/loader.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  ScreenName = 'パスワード変更'
  form: FormGroup
  error: boolean

  constructor(
    private topNav: TopNavService,
    private authService: AuthService,
    private loader: LoaderService,
    private router: Router,
    fb: FormBuilder,
  ) {
    this.form = fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      confirmPassword: ['', [Validators.required]],
    })
  }

  ngOnInit() {
  }

  ChangePassword() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      if (this.form.controls.newPassword.value !== this.form.controls.confirmPassword.value) {
        this.error = true
      }
      return
    }
    this.error = false
    this.loader.Forward(this.authService.ChangePassword(this.form.value)).subscribe(result => {
      //if (result.status === 200) {
      //  this.router.navigateByUrl('/admin')
      //}
    })
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
