import { Component, OnInit, Inject, LOCALE_ID, Input } from '@angular/core';
import { MatDialog, MatCalendarCellCssClasses } from '@angular/material';
import { BaseResult } from '../../../_models/BaseResult';
import { DeviceService } from '../../../_services/device.service';
import { ClassProviderService } from '../../../_services/class-provider.service';
import { LoaderService } from '../../../_services/loader.service';
import { DailyReportService } from '../../../_services/daily-report.service';
import { DailyReport } from '../../../_models/DailyReport';
import { formatDate } from '@angular/common';
import { ReviewDailyDialog } from '../daily-group/review-daily/review-daily.component';

@Component({
  selector: 'app-view-daily-user',
  templateUrl: './view-daily-user.component.html',
  styleUrls: ['./view-daily-user.component.css']
})
export class ViewDailyUserComponent implements OnInit {

  @Input() userId: number
  currentSelectDate: Date
  isMobile: boolean
  reports: DailyReport[]
  fromDate: Date;
  toDate: Date;
  displayText: string

  constructor(
    private dialog: MatDialog,
    private apiService: DailyReportService,
    private loader: LoaderService,
    private clazz: ClassProviderService,
    deviceService: DeviceService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    deviceService.IsMobile.subscribe(value => this.isMobile = value)
  }
  
  ngOnInit() {
    this.Initialize()
  }

  Initialize() {
    this.loader.Forward(this.apiService.GetReportOfUser(this.userId, this.currentSelectDate), true, false).subscribe(result => {
      if (result.status === 200) {
        this.reports = result.data
        this.reports.map(r => {
          r.date = new Date(r.dateValue.year, r.dateValue.month - 1, r.dateValue.day)
        })
        this.fromDate = (result as GetDailyReportResult).fromDate
        this.toDate = (result as GetDailyReportResult).toDate
        this.displayText = '' + formatDate(this.fromDate, 'yyyy年MM月dd日', this.locale) + '～' + formatDate(this.toDate, 'yyyy年MM月dd日', this.locale)
        this.currentSelectDate = new Date(this.fromDate)
      }
    })
  }

  GoDetail(reportId: number) {
    const dialogRef = this.dialog.open(ReviewDailyDialog, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
      data: {
        reportId: reportId,
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isSaveSuccess) {
        this.Initialize()
      }
    })
  }

  RequestReport(reportDate: Date) {
    this.loader.Forward(this.apiService.RequestReport(this.userId, reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.reports.find(r => r.date === reportDate).isReminded = true
      }
    })
  }

  ChangeWeek(offset: number) {
    this.currentSelectDate.setDate(this.currentSelectDate.getDate() + offset)
    this.Initialize()
  }

  GetReportStatusClass(statusId: number) {
    return this.clazz.GetReportStatusClass(statusId)
  }

  dateClass = (d: Date): MatCalendarCellCssClasses => {
    return d.getDay() == 0 ? 'sunday' : d.getDay() == 6 ? 'saturday' : ''
  }
}

interface GetDailyReportResult extends BaseResult {
  fromDate: Date,
  toDate: Date,
}
