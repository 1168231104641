import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceService } from '../../../_services/device.service';
import { LoaderService } from '../../../_services/loader.service';
import { routerAnimation } from '../../../_module/animation/app.animation';
import { TopNavService, TopButtonBehavior, DisplayBehavior } from '../../../_services/route.service';
import { AuthService } from '../../../_services/auth.service';
import { NotificationService } from '../../../_services/notification.service';
import { Subscribable, Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-main-layout',
  templateUrl: './user-main-layout.component.html',
  styleUrls: ['./user-main-layout.component.css'],
  animations: [routerAnimation()]
})
export class UserMainLayoutComponent {
  leftBtn: TopButtonBehavior
  rightBtn: TopButtonBehavior
  centerDisp: DisplayBehavior

  navLinks = [
    { path: '/daily', label: '日報申告' },
    { path: '/weekly', label: '週報申告' },
    //{ path: '/infomation', label: '個人情報' },
  ]

  isAdmin: boolean
  activeRoute: string
  isMobile: boolean
  userName: string
  unreadMessage: number

  navigateStartSubscription: Subscription
  navigateEndSubscription: Subscription

  constructor(
    private router: Router,
    private device: DeviceService,
    private loader: LoaderService,
    private authService: AuthService,
    private notiService: NotificationService,
    topNav: TopNavService,
  ) {
    topNav.leftButton.subscribe(value => this.leftBtn = value)
    topNav.rightButton.subscribe(value => this.rightBtn = value)
    topNav.centerDisplay.subscribe(value => this.centerDisp = value)
    topNav.unreadMessage.subscribe(value => this.unreadMessage = value)
    this.isAdmin = authService.CheckPermissionUser()
  }

  ngOnInit() {
    this.userName = this.authService.GetUserName()
    this.activeRoute = this.router.url;
    this.navigateStartSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart && (this.loader.Loading.value || this.loader.Error.value.error))
      ).subscribe(() => {
        this.loader.Loading.next(false)
        this.loader.Error.next({ error: false })
        this.loader.Subscriptions.forEach(s => s.unsubscribe())
      })

    this.navigateEndSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event) => {
        this.activeRoute = (event as NavigationEnd).url
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.notiService.CountUnread().subscribe(result => {
          if (result.status === 200) {
            this.unreadMessage = result.data
          }
        })
      });
    this.device.IsMobile.subscribe(isMobile => this.isMobile = isMobile)
    this.notiService.CountUnread().subscribe(result => {
      if (result.status === 200) {
        this.unreadMessage = result.data
      }
    })
  }

  ngOnDestroy() {
    this.navigateStartSubscription.unsubscribe();
    this.navigateEndSubscription.unsubscribe();
  }

  GetMyGroup() {
    this.router.navigateByUrl('/group')
  }

  Logout() {
    this.authService.Logout()
    this.router.navigateByUrl('/login')
  }

  assignment() {
    this.router.navigateByUrl('/assignment');
  }
  onOpenedChange(e) {
      document.body.style.overflow = e ? 'hidden' : ''
  }
}
