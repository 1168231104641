import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

}
declare global {
  interface Array<T> {
    clone(): Array<T>
  }
  interface Node {
    is(child: Node): boolean
  }
}

Array.prototype.clone = function () {
  return this.map(x => { return { ...x } })
}
Node.prototype.is = function (target: Node): boolean {
  var result = false
  if (this == target) return true
  if (!this.hasChildNodes()) return false
  for (var i = 0; i < this.childNodes.length; i++) {
    if (this.childNodes.item(i).is(target)) {
      result = true
      break
    }
  }
  return result
}
