import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClassProviderService {

  constructor() { }

  GetReportStatusClass(status: number) {
    switch (status) {
      case 1:
      case 3:
        return 'badge-warning'
      case 2:
        return 'badge-danger'
      case 4:
        return 'badge-success'
      case 5:
        return 'badge-info'
      default:
        return ''
    }
  }

  GetUserStatusClass(statusId: string) {
    switch (statusId) {
      case '有効':
        return 'badge-success'
      case '無効':
        return 'badge-secondary'
      default:
        return ''
    }
  }
}
