import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe extends DatePipe implements PipeTransform {

  transform(value: string | Date, format: string): any {
    if (!value) return ''
    if (!(value instanceof Date)) {
      value = new Date(value)
    }
    var minutes = Math.floor((Date.now() - value.getTime()) / 1000 / 60)
    if (minutes < 1) {
      return '数秒前'
    }
    if (minutes < 60) {
      return `${minutes}分前`
    }
    if (minutes < 24 * 60) {
      var hours = Math.floor(minutes / 60)
      minutes = minutes % 60
      return `${hours}時${minutes}分前`
    }
    return super.transform(value, format)
  }

}
