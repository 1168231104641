import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DeviceService } from '../_services/device.service';
import { BaseResult } from '../_models/BaseResult';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private device: DeviceService,
    private activatedRoute: ActivatedRoute,
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = this.setAuthHeader(req);

    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // 1. Try refresh token
          if (req.url.indexOf("api/account/token") > -1) {
            this.authService.RemoveToken();
            this.router.navigateByUrl(this.router.url.startsWith('/admin') ? '/admin/login' : '/login',
              { queryParams: { returnUrl: returnUrl } });
          }
          else {
            this.authService.RemoveToken();

            return this.authService.RefreshToken()
              .pipe(
                catchError(() => {
                  var returnUrl = this.activatedRoute.url
                  this.router.navigateByUrl(this.router.url.startsWith('/admin') ? '/admin/login' : '/login',
                    { queryParams: { returnUrl: returnUrl } });
                  this.authService.Logout();
                  return throwError(error);
                }),
                map(result => {
                  if (result && result.status === 200) {
                    this.authService.SaveReSession(result)
                    req = this.setAuthHeader(req);
                  }
                }),
                switchMap(() => next.handle(req))
              )
          }
        } else if (err.status === 417) {
          this.authService.Logout();
          this.snackBar.open(err.error || err.statusText, '', { duration: 5000, verticalPosition: this.device.IsMobile.value ? "top": "bottom" });
          var returnUrl = this.activatedRoute.url
          this.router.navigateByUrl(this.router.url.startsWith('/admin') ? '/admin/login' : '/login',
            { queryParams: { returnUrl: returnUrl } });
        } else {
          //var error = err.error.message || err.statusText;
          //this.snackBar.open('エラーが発生しました！', '', { duration: 3000, verticalPosition: this.device.IsMobile.value ? "top": "bottom" });
          var error = {
            status: err.status,
            message: 'エラー：' + err.status + ' ' + (err.status == 404 ? err.statusText : (err.error|| err.statusText)),
          } as BaseResult
          return throwError(error);
        }
    }));
  }


  private setAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    var token = this.authService.CheckUserIsLogin();
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    return req;
  }
}
