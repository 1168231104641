import { Directive, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { fromEvent, Subscription, BehaviorSubject } from 'rxjs';
import { map, pairwise, filter} from 'rxjs/operators';

interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
};

@Injectable({
  providedIn: 'root'
})
@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  @Input()
  scrollOffset = 100;

  @Input()
  container: HTMLElement

  @Output()
  scrollCallback = new EventEmitter<any>()

  constructor() { }

  ngAfterViewInit() {
    this.registerScrollEvent()
  }

  private registerScrollEvent() {
    fromEvent(this.container == document.body ? window : this.container, 'scroll').pipe(
      map(e => {
        var position = {
          sH: this.container.scrollHeight,
          sT: this.container == document.body ? window.scrollY : this.container.scrollTop,
          cH: this.container == document.body ? window.innerHeight : this.container.clientHeight
        } as ScrollPosition
        return position
      }),
      pairwise(),
      filter(positions => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1]))
    ).subscribe(result => this.scrollCallback.emit(result))
  }

  private isUserScrollingDown = (positions) => {
    return positions[0].sT < positions[1].sT;
  }

  private isScrollExpectedPercent = (position) => {
    return position.sT + position.cH > position.sH - this.scrollOffset;
  }
}
