import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../_services/loader.service';
import { UserApiService } from '../../../_services/user.api.service';
import { AuthService } from '../../../_services/auth.service';
import { User } from '../../../_models/User';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.css']
})
export class MyInfoComponent implements OnInit {

  userId: number
  user: User

  constructor(
    private loader: LoaderService,
    private apiService: UserApiService,
    authService: AuthService,
    topNav: TopNavService,
  ) {
    this.userId = authService.GetCurrentUserId()
    topNav.centerDisplay.next({ text: '個人情報' })
  }

  async ngOnInit() {
    var result = await this.loader.Forward(this.apiService.GetMyInfo(), true, true).toPromise()
    if (result.status === 200) {
      this.user = result.data
    }
  }

}
