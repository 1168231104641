import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GroupApiService } from '../../../_services/group.api.service';
import { LoaderService } from '../../../_services/loader.service';
import { Group } from '../../../_models/Group';
import { Member } from '../../../_models/Member';
import { MatTableDataSource } from '@angular/material';
import { DeviceService } from '../../../_services/device.service';
import { AuthService } from '../../../_services/auth.service';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-user-group-detail',
  templateUrl: './user-group-detail.component.html',
  styleUrls: ['./user-group-detail.component.css']
})
export class UserGroupDetailComponent implements OnInit, OnDestroy {
  ScreenName: string
  isMobile: boolean
  groupId: string
  group: Group
  members: Member[]
  tabIndex: number = 0

  isAdmin: boolean
  isAllowAdd: boolean
  isAllowRem: boolean
  isAllowDaily: boolean
  isAllowWeekly: boolean

  extTabs = []

  columnIds = ['name', 'groupRoleName', 'action']
  dataSource: MatTableDataSource<Member>

  constructor(
    private router: Router,
    private apiService: GroupApiService,
    private loader: LoaderService,
    private authService: AuthService,
    private topNav: TopNavService,
    device: DeviceService,
    activeRoute: ActivatedRoute,
  ) {
    activeRoute.paramMap.subscribe(params => {
      this.groupId = params.get('groupId')
    })
    device.IsMobile.subscribe(value => this.isMobile = value)
    topNav.leftButton.next({
      icon: 'arrow_back',
      routerLink: '/group',
    })
    topNav.rightButton.next(null)
    this.isAdmin = authService.CheckPermissionUser()
  }

  ngOnInit() {
    this.Initialize()
  }
  ngOnDestroy() {
    this.topNav.Reset()
  }

  @HostListener('document:click', ['$event.target'])
  onReload(target) {
    if (target == document.getElementById('btnReload')
      || target.parentElement == document.getElementById('btnReload'))
      this.Initialize()
  }

  Initialize() {
    this.loader.Forward(this.apiService.GetGroup(this.groupId), true, true).subscribe(result => {
      if (result.status === 200) {
        this.group = result.data.group
        this.ScreenName = this.group.name
        this.topNav.centerDisplay.next({ text: this.group.name })
        this.members = result.data.members
        this.members.map(u => u['isManager'] = this.group.managers.some(m => m.id === u.id))
        this.dataSource = new MatTableDataSource<Member>(this.members)
        var authority = this.authService.GetMemberAuthority(+this.groupId)
        this.isAllowAdd = authority.includes('Add')
        this.isAllowRem = authority.includes('Remove')
        this.isAllowDaily = this.authService.GetDailyAuthority(+this.groupId).includes('Approve')
        this.isAllowWeekly = this.authService.GetWeeklyAuthority(+this.groupId).includes('Approve')
      }
    })
  }

  ViewDailyUser(userId: number) {
    var member = this.members.find(m => m.id == userId)
    if (member) {
      var idx = this.extTabs.findIndex(t => t.userId == member.id && t.type == 0)
      if (idx >= 0) {
        this.tabIndex = idx + 3
      } else {
        this.extTabs.push({
          userId: member.id,
          name: `${member.name}（日報）`,
          type: 0,
        })
        this.tabIndex = this.extTabs.length + 3 - 1
      }
    }
  }

  ViewWeeklyUser(userId: number) {
    var member = this.members.find(m => m.id == userId)
    if (member) {
      var idx = this.extTabs.findIndex(t => t.userId == member.id && t.type == 1)
      if (idx >= 0) {
        this.tabIndex = idx + 3
      } else {
        this.extTabs.push({
          userId: member.id,
          name: `${member.name}（週報）`,
          type: 1,
        })
        this.tabIndex = this.extTabs.length + 3 - 1
      }
    }
  }

  RemoveTab(index: number) {
    this.extTabs.splice(index, 1)
    this.tabIndex = 0
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
