import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupApiService } from 'src/app/_services/group.api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Member } from 'src/app/_models/Member';
import { MatTableDataSource, MatSort } from '@angular/material';
import { LoaderService } from 'src/app/_services/loader.service';
import { ClassProviderService } from '../../../_services/class-provider.service';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {

  ScreenName: string = 'グループ詳細'
  groupId: string

  Group: any = {}
  Members: Member[] = []

  columnIds = ['id', 'name', 'status', 'groupRoleName', 'joinedDate']
  dataSource: MatTableDataSource<Member>
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private topNav: TopNavService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: GroupApiService,
    private loader: LoaderService,
    private clazz: ClassProviderService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('groupId')
    })
    this.GetDetail()
  }

  async GetDetail() {
    var result = await this.loader.Forward(this.apiService.GetGroup(this.groupId), true, true).toPromise();
    if (result.status === 200) {
      this.Group = result.data.group;
      this.Members = result.data.members;
      this.dataSource = new MatTableDataSource<Member>(this.Members);
      this.dataSource.sort = this.sort
    }
  }
  GetUserStatusClass(status: string) {
    return this.clazz.GetUserStatusClass(status)
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
