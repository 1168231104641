import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { LoaderService } from '../../../_services/loader.service';
import { DailyReport } from '../../../_models/DailyReport';
import { WeeklyReport } from '../../../_models/WeeklyReport';
import { MatDialog } from '@angular/material';
import { WeeklyReportService } from '../../../_services/weekly-report.service';
import { TopNavService } from '../../../_services/route.service';
import { ClassProviderService } from '../../../_services/class-provider.service';
import { DeviceService } from '../../../_services/device.service';
import { fromEvent } from 'rxjs';
import { BaseResult } from '../../../_models/BaseResult';
import { formatDate } from '@angular/common';
import { WeeklyCreateDialog } from './weekly-create/weekly-create.component';

@Component({
  selector: 'app-my-weekly-list',
  templateUrl: './my-weekly-list.component.html',
  styleUrls: ['./my-weekly-list.component.css']
})
export class MyWeeklyListComponent implements OnInit {

  currentSelectDate: Date
  isMobile: boolean
  reports: WeeklyReport[]
  fromDate: Date;
  toDate: Date;
  displayText: string

  constructor(
    private dialog: MatDialog,
    private apiService: WeeklyReportService,
    private loader: LoaderService,
    private topNav: TopNavService,
    private clazz: ClassProviderService,
    deviceService: DeviceService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    deviceService.IsMobile.subscribe(value => this.isMobile = value)
    this.topNav.leftButton.next({
      id: 'preMonthBtn',
      icon: 'chevron_left',
    })
    this.topNav.rightButton.next({
      id: 'nextMonthBtn',
      icon: 'chevron_right',
    })
    this.topNav.centerDisplay.next({
      text: this.displayText
    })
    this.RegisterEvent()
  }
  RegisterEvent() {
    fromEvent(document, 'click').subscribe(event => {
      var element = document.getElementById('btnReload')
      if (element && element.is(event.target as Node)) {
        this.Initialize()
      }
    })
    fromEvent(document, 'click').subscribe(event => {
      var element = document.getElementById('preMonthBtn')
      if (element && element.is(event.target as Node)) {
        this.changeMonth(-1)
      }
    })
    fromEvent(document, 'click').subscribe(event => {
      var element = document.getElementById('nextMonthBtn')
      if (element && element.is(event.target as Node)) {
        this.changeMonth(1)
      }
    })
  }

  ngOnInit() {
    this.Initialize()
  }
  ngOnDestroy() {
    this.topNav.Reset()
  }

  async Initialize() {
    var result = await this.loader.Forward(this.apiService.GetReports(this.currentSelectDate), true, true).toPromise()
    if (result.status === 200) {
      this.reports = result.data
      this.reports.map(r => {
        r.date = new Date(r.dateValue.year, r.dateValue.month - 1, r.dateValue.day)
      })
      this.fromDate = (result as GetWeeklyReportResult).fromDate
      this.toDate = (result as GetWeeklyReportResult).toDate
      this.displayText = '' + formatDate(this.fromDate, 'yyyy年MM月dd日', this.locale) + '～' + formatDate(this.toDate, 'yyyy年MM月dd日', this.locale)
      this.topNav.centerDisplay.next({
        text: this.displayText
      })
      this.currentSelectDate = new Date(this.fromDate)
    }
  }

  Create(report: DailyReport) {
    const dialogRef = this.dialog.open(WeeklyCreateDialog, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
      data: report,
      minWidth: this.isMobile ? '100vw' : '90vw'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isSaveSuccess) {
        this.Initialize()
      }
    })
  }
  async changeMonth(offset: number) {
    this.currentSelectDate.setMonth(this.currentSelectDate.getMonth() + offset)
    await this.Initialize()
  }

  GetReportStatusClass(statusId: number) {
    return this.clazz.GetReportStatusClass(statusId)
  }
}

interface GetWeeklyReportResult extends BaseResult {
  fromDate: Date,
  toDate: Date,
}

