import { Component, OnInit } from '@angular/core'
import { MatTableDataSource, MatDialog, MatPaginatorIntl, PageEvent, MatSnackBar } from '@angular/material'
import { Salary } from '../../../_models/Salary'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { AuthService } from '../../../_services/auth.service'
import { PreviewFilePopup } from '../../common/popups/preview-file/preview-file.component'
import { TopNavService } from '../../../_services/route.service'
import { LoaderService } from '../../../_services/loader.service'
import { ConfirmFilePopup } from '../../common/popups/confirm-file/confirm-file.component'
import { DeviceService } from '../../../_services/device.service'
import { HttpEventType } from '@angular/common/http'
import { log } from 'util'
import { ManagementFileService } from '../../../_services/management-file.service'

@Component({
  selector: 'app-salary-list-client',
  templateUrl: './salary-list-client.component.html',
  styles: ['.full-width-input {width: 100%}'],
})
export class ListSalaryClientComponent implements OnInit {

  ScreenName: string;
  fileNameTitle: string;
  Salarys: Salary[] = []
  columnIds = ['nameFile', 'statusRead','selectedDate', 'salaryID']
  dataSource: MatTableDataSource<Salary>
  typeScreen: string;
  isNotData: boolean = false;
  isHasData: boolean = false;
  messNotData: string = '給与データがありません。';
  typeGet: number;
  userId: number = 0;
  isAdmin: boolean;
  pageLength: number
  pageSize: number
  pageIndex: number
  pageSizeOptions = [25, 50, 100, 200]
  isMobile: boolean

  constructor(
    private loader: LoaderService,
    private dialog: MatDialog,
    private authService: AuthService,
    private managementFileService: ManagementFileService,
    private route: ActivatedRoute,
    private router: Router,
    private topNav: TopNavService,
    paginator: MatPaginatorIntl,
    deviceService: DeviceService,
    private snackBar: MatSnackBar
  ) {
    deviceService.IsMobile.subscribe(value => this.isMobile = value)
    topNav.leftButton.next({
      icon: 'arrow_back',
      routerLink: '/assignment',
    })
    topNav.rightButton.next(null)
    paginator.itemsPerPageLabel = "１ページあたり件数"
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) { return `合計${length}の0` }
      length = Math.max(length, 0)
      const startIndex = page * pageSize
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
      return `合計${length}の${startIndex + 1}～${endIndex}`
    }
  }

  ngOnInit() {
    try {
      this.loader.Loading.next(true);
      this.isAdmin = this.authService.CheckPermissionUser();
      this.getTypeScreen();

      this.pageIndex = 0
      this.pageSize = this.pageSizeOptions[0]
      this.getListFile();
      this.loader.Loading.next(false);
    } catch (e) {
      this.loader.Loading.next(false);
      console.log(e)
    }
  }

  ngOnDestroy() {
    this.topNav.Reset()
  }

  getTypeScreen() {
    this.route.queryParams
      .subscribe(params => {
        this.typeScreen = params.files;
        if (this.typeScreen == 'salary') {
          this.ScreenName = '給与明細参照';
          this.fileNameTitle = '給与明細';
          this.typeGet = 1;
          this.topNav.centerDisplay.next({ text: this.ScreenName })
        }
        else if (this.typeScreen == 'bonus') {
          this.ScreenName = '賞与明細参照';
          this.fileNameTitle = '賞与明細';
          this.messNotData = '賞与データがありません。';
          this.typeGet = 2;
          this.topNav.centerDisplay.next({ text: this.ScreenName })
        }
        else if (this.typeScreen == 'withholding') {
          this.ScreenName = '源泉徴収票';
          this.fileNameTitle = '源泉徴収票明細';
          this.messNotData = 'データがありません。';
          this.typeGet = 5;
          this.topNav.centerDisplay.next({ text: this.ScreenName })
        }
        else if (this.typeScreen == 'tax') {
          this.ScreenName = '源泉徴収票参照';
          this.fileNameTitle = '源泉徴収票';
          this.messNotData = '納税データがありません。';
          this.typeGet = 6;
          this.topNav.centerDisplay.next({ text: this.ScreenName })
        }
        else if (this.typeScreen == 'all') {
          this.ScreenName = 'ファイル送信履歴';
          this.messNotData = 'ファイルがありません。';
          this.typeGet = 4;
          this.userId = this.authService.getUserViewHistory();
          let user = this.authService.getUserSelected();
          if (user !== null && user.length !== 0) {
            this.ScreenName = user.split('-')[0];
          }
          this.topNav.centerDisplay.next({ text: this.ScreenName })
        }
      });
  }

  getListFile() {
    this.loader.Loading.next(true);
    this.managementFileService.GetAllFileInfo(this.typeGet, this.userId, this.pageIndex + 1, this.pageSize).subscribe(result => {
      if (result.status === 200) {
        this.Salarys = result.data.files;
        this.pageLength = result.data.count;
        this.dataSource = new MatTableDataSource<Salary>(this.Salarys);
        if (this.Salarys.length == 0) {
          this.setStatusHaveData(true);
        }
        else {
          this.setStatusHaveData(false);
        }
      }
      else {
        this.setStatusHaveData(true);
      }
    });
    this.loader.Loading.next(false);
  }

  ChangePage(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex = 0
    } else {
      this.pageIndex = event.pageIndex
    }
    this.pageSize = event.pageSize
    this.getListFile();
  }

  setStatusHaveData(isHaveData: boolean) {
    if (isHaveData) {
      this.isNotData = true;
      this.isHasData = false;
    }
    else {
      this.isNotData = false;
      this.isHasData = true;
    }
  }

  viewFile(data: Salary) {
    this.topNav.linkFile.next(data.url);
    this.topNav.dataTransfer.next(data);
    this.loader.Loading.next(true);
    let dialogRef = this.dialog.open(PreviewFilePopup, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
    });
    this.updateStatus(data);
    dialogRef.afterClosed().subscribe(() => { this.loader.Loading.next(false); this.authService.setStatusOpenFile('0'); });
  }

  deleteFile(salary: Salary) {
    try {
      let dialogRef = this.dialog.open(ConfirmFilePopup, {
        data: salary.nameFile
      });
      dialogRef.afterClosed().subscribe(actionNumber => {
        if (actionNumber == 1) {
          this.loader.Loading.next(true);
          this.authService.deleteFile(salary.salaryID).subscribe(result => {
            if (result) {
              this.getListFile();
              this.loader.Loading.next(false);
            }
            else {
              this.loader.Loading.next(false);
              this.snackBar.open('ファイル削除' + salary.nameFile + '失敗', null, { duration: 3000 })
            }
          });
        }
      })
    } catch (e) {
      this.loader.Loading.next(false);
      console.log(e);
    }
  }

  downloadFile(salary: Salary) {
    try {
      this.snackBar.open("バックグラウンドでダウンロード中", null, { duration: 3000 })
      this.authService.downloadFile(salary.salaryID).subscribe(data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            console.log(`${data.loaded}/${data.total}`)
            break
          case HttpEventType.Response:
            var downloadFileContent = new Blob([data.body], { type: data.body.type })
            const a = document.createElement('a')
            a.setAttribute('style', 'display:none;')
            document.body.appendChild(a)
            a.download = salary.nameFile
            a.href = URL.createObjectURL(downloadFileContent)
            a.target = '_blank'
            a.click()
            document.body.removeChild(a)
            this.updateStatus(salary);
            break
        }
      }, err => {
        this.loader.Loading.next(false);
        console.log(err)
      })
    } catch (e) {
      this.loader.Loading.next(false);
      console.log(e)
    }
  }

  removeItem(fileRemove: Salary) {
    const index: number = this.Salarys.indexOf(fileRemove);
    if (index !== -1) {
      this.Salarys.splice(index, 1);
      if (this.Salarys.length == 0) {
        this.messNotData = "ファイルがありません。";
        this.isNotData = true;
        this.isHasData = false;
      }
      else {
        this.dataSource = new MatTableDataSource<Salary>(this.Salarys);
      }
    }
  }

  updateStatus(salary: Salary) {
    if (!salary.isRead && this.typeGet != 4) {
      this.loader.Loading.next(true);
      try {
        this.authService.updateReadFile(salary.salaryID).subscribe(value => {
          salary.isRead = true;
          salary.statusRead = '開封済';
          salary.background = "#fff";
        });
        this.loader.Loading.next(false);
      } catch (e) {
        this.loader.Loading.next(false);
      }
    }
  }

  toUser() {
    this.topNav.typeOpenScreenUser.next(this.typeGet);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin/user");
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
 
}
