import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatSnackBar, MatDialog, MatSort } from '@angular/material';
import { TimeSplitFile } from '../../../_models/TimeSplitFile';
import { AuthService } from '../../../_services/auth.service';
import { LoaderService } from '../../../_services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { TopNavService } from '../../../_services/route.service';
import { User } from '../../../_models/User';
import { UserApiService } from '../../../_services/user.api.service';
import { Salary } from '../../../_models/Salary';
import { all } from 'q';
import { FormControl, Validators } from '@angular/forms';
import { PreviewFilePopup } from '../../common/popups/preview-file/preview-file.component';
import { ConfirmPopup } from '../../common/popups/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-salary-list',
  templateUrl: './salary-list.component.html',
  styleUrls: ['./salary-list.component.css']
})
export class SalaryListComponent implements OnInit, AfterViewInit {

  ScreenName: string = "配布ファイル管理"
  compCd1: string = window.sessionStorage.getItem("salarylist-compCd1") || "all"
  fileType: string = window.sessionStorage.getItem("salarylist-fileType") || "all"
  isPublish: string = window.sessionStorage.getItem("salarylist-isPublish") || "all"

  compCd2: string = window.sessionStorage.getItem("salarylist-compCd2") || ""
  emplCdLst: User[]
  emplCd: string = window.sessionStorage.getItem("salarylist-emplCd") || ""

  searchMode: string = window.sessionStorage.getItem("salarylist-searchMode") || ""

  listTimeSplitFiles: Salary[]

  columnIds = ['salaryID', 'compCode', 'emplCd', 'userName', 'fileType', 'nameFile', 'selectedDate', 'isPublish', 'isRead', 'action']
  dataSource: MatTableDataSource<Salary> = new MatTableDataSource<Salary>()

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isDisplayNoData: boolean = false

  compCtrl = new FormControl('', Validators.required);
  emplCtrl = new FormControl('', Validators.required);

  constructor(
    private router: Router,
    private authService: AuthService,
    private loader: LoaderService,
    private topNav: TopNavService,
    private userService: UserApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.searchMode == "1") {
      this.compCd2 = "";
      this.emplCd = "";
      this.GetTimeSplitFile(this.searchMode);
    } else if (this.searchMode == "2") {
      if (this.compCd2) {
        this.compCd1 = "all";
        this.fileType = "all";
        this.isPublish = "all";
        this.GetEmployee(true);
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  GetTimeSplitFile(searchMode: string) {
    if (searchMode == "2") {
      this.compCtrl.markAsTouched();
      this.emplCtrl.markAsTouched();
      if (this.compCtrl.hasError('required') || this.emplCtrl.hasError('required')) {
        return;
      }
    }

    window.sessionStorage.setItem("salarylist-compCd1", this.compCd1)
    window.sessionStorage.setItem("salarylist-fileType", this.fileType)
    window.sessionStorage.setItem("salarylist-isPublish", this.isPublish)
    window.sessionStorage.setItem("salarylist-compCd2", this.compCd2)
    window.sessionStorage.setItem("salarylist-emplCd", this.emplCd)
    window.sessionStorage.setItem("salarylist-searchMode", searchMode)
    try {
      this.loader.Loading.next(true);
      if (searchMode == "1") {
        this.authService.GetTimeSplitFile(this.fileType, this.compCd1, this.isPublish).subscribe(data => {
          this.listTimeSplitFiles = data;
          this.dataSource = new MatTableDataSource<Salary>(this.listTimeSplitFiles);
          this.dataSource.sort = this.sort;
          this.searchMode = searchMode;
          this.isDisplayNoData = this.listTimeSplitFiles.length == 0;
          this.loader.Loading.next(false);
        }, error => {
            this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
            this.loader.Loading.next(false);
        });
      } else if (searchMode == "2") {
        this.authService.GetTimeSplitFileByEmplCd(this.compCd2, this.emplCd).subscribe(data => {
          this.listTimeSplitFiles = data;
          this.dataSource = new MatTableDataSource<Salary>(this.listTimeSplitFiles);
          this.dataSource.sort = this.sort;
          this.searchMode = searchMode;
          this.isDisplayNoData = this.listTimeSplitFiles.length == 0;
          this.loader.Loading.next(false);
        }, error => {
          this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
          this.loader.Loading.next(false);
        });
      }
    } catch {
      this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
      this.loader.Loading.next(false);
    }
  }
  SalaryDetail(element: Salary) {
    var date = new Date(element.selectedDate)
    var strTime = "" + date.getFullYear()
    if (date.getMonth() + 1 > 9) {
      strTime += (date.getMonth() + 1)
    } else {
      strTime += '0' + (date.getMonth() + 1)
    }
    this.router.navigateByUrl(`/admin/salary/${element.companyCode}/${strTime}/${element.fileType}/${element.isPublish ? "1" : "0"}`)
  }

  GetEmployee(isSearch: boolean = false) {
    this.loader.Loading.next(true);
    try {
      this.userService.GetEmployee(this.compCd2).subscribe(data => {
        if (data.status == 200) {
          this.emplCdLst = data.data
          if (this.emplCdLst.every(e => e.employeeCode !== this.emplCd)) {
            this.emplCd == null;
          } else {
            if (isSearch) {
              this.GetTimeSplitFile("2");
            }
          }
          this.loader.Loading.next(false);
        }
      })
    } catch {
      this.snackBar.open('社員一覧を取得できません。', null, { duration: 3000 })
      this.loader.Loading.next(false);
    }
  }

  Preview(salary: Salary) {
    this.topNav.linkFile.next(salary.url);
    this.topNav.dataTransfer.next(salary);
    this.loader.Loading.next(true);
    let dialogRef = this.dialog.open(PreviewFilePopup, {
      panelClass: ['no-pad-dialog', 'pc'],
    });
    dialogRef.afterClosed().subscribe((_) => {
      this.authService.setStatusOpenFile('0');
    })
  }

  deleteFile(salary: Salary) {
    let dialogRef = this.dialog.open(ConfirmPopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: {
        title: "ファイル削除",
        message: `『${salary.name}』氏の『${salary.nameFile}』を削除します。よろしいですか。`,
        btnPrimary: "はい",
        btnWarn: "いいえ"
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null && data.btn == "btnPrimary") {
        this.loader.Loading.next(true);
        this.authService.deleteFile(salary.salaryID).subscribe(result => {
          if (result) {
            this.GetTimeSplitFile(this.searchMode);
            this.loader.Loading.next(false);
          }
          else {
            this.loader.Loading.next(false);
            this.snackBar.open('ファイル削除' + salary.nameFile + '失敗', null, { duration: 3000 })
          }
        }, error => {
          this.loader.Loading.next(false);
          this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
        });
      }
    });
  }

  deleteAllFile(salary: Salary) {
    let dialogRef = this.dialog.open(ConfirmPopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: {
        title: "ファイル削除",
        message: `『${this.TransformCompCd(salary.companyCode)}』会社の『${salary.nameFile}』全てを削除します。よろしいですか。`,
        btnPrimary: "はい",
        btnWarn: "いいえ"
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null && data.btn == "btnPrimary") {
        this.loader.Loading.next(true);
        var date = new Date(salary.selectedDate)
        var strTime = "" + date.getFullYear()
        if (date.getMonth() + 1 > 9) {
          strTime += (date.getMonth() + 1)
        } else {
          strTime += '0' + (date.getMonth() + 1)
        }
        this.authService.deleteAllFile(salary.companyCode, salary.fileType.toString(), strTime).subscribe(result => {
          if (result) {
            this.GetTimeSplitFile(this.searchMode);
            this.loader.Loading.next(false);
          }
          else {
            this.loader.Loading.next(false);
            this.snackBar.open('ファイル削除' + salary.nameFile + '失敗', null, { duration: 3000 })
          }
        }, error => {
          this.loader.Loading.next(false);
          this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
        });
      }
    });
  }

  TransformFileType(type: string) {
    if (type == "1")
      return "給与明細配布"
    else if (type == "2")
      return "賞与明細配布"
    else if (type == "5" || type == "6")
      return "源泉徴収票"
    else return ""
  }
  TransformCompCd(compCd: string) {
    if (compCd == "COM01")
      return "アワードシステム"
    else if (compCd == "COM02")
      return "サンライトシステムサービス"
    else return ""
  }
  TransformTime(time: string) {
    if (time.length == 6) {
      return time.substr(2, 4) + "年" + time.substr(0, 2) + "月";
    } else if (time.length == 4) {
      return time + "年"
    } else return ""
  }
  TransformPublish(isPublish: boolean) {
    if (isPublish == true)
      return "配布済"
    else return "未配布"
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
