import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { CalendarPopup } from '../calendar-popup/calendar-popup.component';
import { TopNavService } from '../../../../_services/route.service';
import { AuthService } from '../../../../_services/auth.service';
import { CategoriesAction } from '../../../../_models/CategoriesAction';
import { HelperService } from '../../../../_services/helper.service';
import { TimeSplitFile } from '../../../../_models/TimeSplitFile';
import { ConfirmFilePopup } from '../confirm-file/confirm-file.component';

@Component({
  selector: 'app-company-popup',
  templateUrl: './company-popup.component.html',
  styleUrls: ['./company-popup.component.css']
})
export class CompanyDialog implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUploadButton: ElementRef;
  typeSelect: number;
  category: CategoriesAction;
  userIdSelected: number = 0;
  isDuplicateFile: boolean;
  strTime: string;
  strTimeSplit: string;
  compCode: string;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CompanyDialog>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private helper: HelperService,
    private topNav: TopNavService) {
    topNav.typeOpenCompanyPopup.subscribe(value => this.typeSelect = value);
    topNav.categories.subscribe(value => {
      this.category = value;
    });
  }

  ngOnInit() {
    if (!this.category.isShowCalendar) {
      //this.helper.getAllTimeSplitFile();
    }
  }

  async clickCompany(companySelect: number) {
    this.fileUploadButton.nativeElement.value = "";
    this.isDuplicateFile = false;
    this.topNav.companySelect.next(companySelect);
    // 給与明細配布 (1)
    // 賞与明細配布 (2)
    if (this.category.categoriesID == 1 || this.category.categoriesID == 2) {
      if (companySelect == 1) {
        this.authService.setCompanyCode('COM01');
        //this.compCode == 'COM01';
        //if (!this.category.isShowCalendar) {
        //  this.helper.getAllTimeSplitFile();
        //}
      }
      else {
        this.authService.setCompanyCode('COM02');
        //this.compCode = 'COM02';
        //if (!this.category.isShowCalendar) {
        //  this.helper.getAllTimeSplitFile();
        //}
      }
      if (this.category.isShowCalendar) {
        var dialogRefCalendar = this.dialog.open(CalendarPopup);
        dialogRefCalendar.afterClosed().subscribe(data => {
          const fileData = data as File;
          this.dialogRef.close(fileData);
        })
      }
      else {
        // todo check time
        let timeSelected = new Date();
        this.strTime = this.helper.getTime(timeSelected);
        this.strTimeSplit = this.helper.getTimeSplit(this.strTime);
        if (!(await this.helper.timeValid(this.strTimeSplit, this.category.categoriesID.toString(),
          this.category.typeSend, this.userIdSelected, companySelect == 1 ? 'COM01' : 'COM02', false))) {
          let mess = this.helper.messConfirmDuplicateTime(this.category.categoriesID.toString(), this.strTimeSplit);
          if (confirm(mess)) {
            // open explorer
            this.isDuplicateFile = true;
            this.fileUploadButton.nativeElement.accept = this.category.typeAccept;
            this.fileUploadButton.nativeElement.click();
          }
        }
        else {
          this.fileUploadButton.nativeElement.accept = this.category.typeAccept;
          this.fileUploadButton.nativeElement.click();
        }
      }
    }
    // 社員ID追加 (3)
    else {
      this.fileUploadButton.nativeElement.accept = this.category.typeAccept;
      this.fileUploadButton.nativeElement.click();
    }
  }

  async onFileSelectLocal(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      if (this.category.categoriesID == 3) {
        let fileUnvalid = await this.helper.isCSV(fileToRead.name);
        if (!fileUnvalid) {
          this.fileUploadButton.nativeElement.value = "";
          this.snackBar.open("フォーマットファイルが無効です。", null, { duration: 3000 })
        }
        else {
          this.dialogRef.close(fileToRead);
        }
      }
      else if (!this.category.isShowCalendar && (this.category.categoriesID == 1
        || this.category.categoriesID == 2)) {
        let isPdf = this.helper.isPDF(fileToRead.name)
        if (!isPdf) {
          this.fileUploadButton.nativeElement.value = "";
          this.snackBar.open("フォーマットファイルが無効です。", null, { duration: 3000 })
        }
        else {
          if (!this.isDuplicateFile) {
            let dialogRef = this.dialog.open(ConfirmFilePopup, {
              data: fileToRead.name
            });
            dialogRef.afterClosed().subscribe(actionNumber => {
              if (actionNumber == 1) {
                this.topNav.timeSelect.next(this.strTime);
                this.dialogRef.close(fileToRead);
              }
            });
          }
          else {
            this.topNav.timeSelect.next(this.strTime);
            this.dialogRef.close(fileToRead);
          }

          //// set time selected = current time
          //let timeSelected = new Date();
          //let strTime = this.helper.getTime(timeSelected);
          //let strTimeSplit = this.helper.getTimeSplit(strTime);
          //if (!this.helper.timeValid(strTimeSplit, this.category.categoriesID.toString(), this.category.typeSend, this.userIdSelected)) {
          //  let mess = this.helper.messConfirmDuplicateTime(this.category.categoriesID.toString(), strTimeSplit);
          //  if (confirm(mess)) {
          //    this.topNav.timeSelect.next(strTime);
          //    this.dialogRef.close(fileToRead);
          //  }
          //}
          //else {
          //  this.topNav.timeSelect.next(strTime);
          //  this.dialogRef.close(fileToRead);
          //}
        }
      }
    }
  }
}


