import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { N_Notification } from '../../../../_models/Notification';
import { ClassProviderService } from '../../../../_services/class-provider.service';
import { DeviceService } from '../../../../_services/device.service';

@Component({
  selector: 'app-notice-detail-dialog',
  templateUrl: './notice-detail-dialog.component.html',
})
export class NoticeDetailDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: N_Notification,
  ) { }
}

@Component({
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
})
export class NoticeDetailComponent {
  @Input() detail: N_Notification
  isMobile: boolean
  constructor(
    divice: DeviceService,
  ) {
    divice.IsMobile.subscribe(value => this.isMobile = value)
  }
}
