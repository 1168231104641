import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { TimeSplitFile } from '../_models/TimeSplitFile';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService implements OnInit {
  TimeSplitFiles: TimeSplitFile[] = [];

  constructor(
    private accountService: AuthService,
  ) {
  }

  ngOnInit(): void {

  }

  async isCSV(nameFile: string) {
    let valid = false;
    const arrExtendFile = ['.xlsx', '.xls', '.csv'];
    let indextExtend = nameFile.lastIndexOf('.');
    let extendFile = nameFile.substring(indextExtend);
    await arrExtendFile.forEach(function (value) {
      if (value == extendFile) {
        valid = true;
      }
    });
    return valid;
  }

  isPDF(nameFile: string) {
    let valid = false;
    const extendFileConst = '.pdf';
    let indextCut = nameFile.lastIndexOf(extendFileConst);
    let extendFile = nameFile.substring(indextCut);
    if (extendFile == extendFileConst) {
      valid = true;
    }
    return valid;
  }

  getTime(timeSelected: Date) {
    let day: string = timeSelected.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (timeSelected.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = timeSelected.getFullYear();
    return year + '' + month + '' + day;
  }

  async timeValid(strTime: string, type: string, typeSend: number, userId: number, compCode: string, isPublish: boolean) {
    //this.getAllTimeSplitFile();
    this.TimeSplitFiles = await this.accountService.getAllTimeSplitFile().toPromise();
    this.TimeSplitFiles;
    let status = true;
    this.TimeSplitFiles.forEach(function (value) {
      if (typeSend == 2) {
        if (strTime == value.strTime && type == value.fileType && type != '6' && value.compCode == compCode && value.isPublish == isPublish) {
          status = false;
        }
        }
      else if (typeSend == 1) {
        let timeCheck = strTime;
        if (type == '5') {
          timeCheck = timeCheck.substr(2, 4);
        }
        if (timeCheck == value.strTime && type == value.fileType && value.userID == userId) {
          status = false;
        }
      }
    });
    return status;
  }

  getTimeSplit(strTimeSelected: string) {
    let year = strTimeSelected.substr(0, 4);
    let month = strTimeSelected.substr(4, 2);
    return month + year;
  }

  messConfirmDuplicateTime(fileType: string, strTimeSplit: string) {
    let strFileType = '給与';
    let strTime = strTimeSplit.substring(2) + '年' + (fileType == '6' ? '' : strTimeSplit.substring(0, 2) + '月');
    switch (fileType) {
      case '1':
        strFileType = '給与';
        break;
      case '2':
        strFileType = '賞与';
        break;
      case '5':
        strTime = strTimeSplit.substr(2, 4) + '年';
        strFileType = '源泉徴収票';
        break;
      case '6':
        strFileType = '源泉徴収票';
        break;
    }
    let mess = (fileType == '1' || fileType == '2' || fileType == '6' ? '未配布の' : '') + '「' + strFileType + strTime + '」ファイルはすでに存在します。\n更新したいですか？';
    return mess;
  }
}
