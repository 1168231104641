import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmPopupData } from '../../../../_models/ConfirmPopupData';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopup implements OnInit {

  _data: ConfirmPopupData
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmPopupData,
      private dialogRef: MatDialogRef<ConfirmPopup>
  ) {
    this._data = data
  }

  ngOnInit() {
  }

  btnPrimaryClick() {
    this.dialogRef.close({ btn: "btnPrimary" });
  }
  btnAccentClick() {
    this.dialogRef.close({ btn: "btnAccent" });
  }
  btnWarnClick() {
    this.dialogRef.close({ btn: "btnWarn" });
  }
  btnDefaultClick() {
    this.dialogRef.close({ btn: "btnDefault" });
  }
}
