import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { GroupApiService } from '../../../_services/group.api.service';
import { LoaderService } from '../../../_services/loader.service';
import { DeviceService } from '../../../_services/device.service';
import { AuthService } from '../../../_services/auth.service';
import { TopNavService } from '../../../_services/route.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-my-group',
  templateUrl: './my-group.component.html',
  styleUrls: ['./my-group.component.css']
})
export class MyGroupComponent implements OnInit, OnDestroy {

  ScreenName = 'グループ一覧'
  userId: number
  isMobile: boolean
  isAdmin: boolean
  groups: any[]
  constructor(
    private router: Router,
    private apiService: GroupApiService,
    private loader: LoaderService,
    private topNav: TopNavService,
    Device: DeviceService,
    authService: AuthService,
  ) {
    Device.IsMobile.subscribe(value => this.isMobile = value)
    this.userId = authService.GetCurrentUserId()
    this.isAdmin = authService.CheckPermissionUser()
    topNav.leftButton.next(null)
    topNav.rightButton.next(null)
    topNav.centerDisplay.next({ text: 'マイグループ' })
  }

  ngOnInit() {
    this.Initialize()
  }
  ngOnDestroy() {
    this.topNav.Reset()
  }

  @HostListener('document:click', ['$event.target'])
  onReload(target) {
    if (target == document.getElementById('btnReload')
      || target.parentElement == document.getElementById('btnReload'))
      this.Initialize()
  }

  Initialize() {
    var request = this.isAdmin ? this.apiService.GetAllGroup() : this.apiService.GetMyGroup()
    this.loader.Forward(request, true, false).subscribe(result => {
      if (result.status === 200) {
        this.groups = result.data
        this.groups.map(g => {
          g.isManager = (g.managers && g.managers.some(m => m.id === this.userId))
        })
      }
    })
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
