import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { TopNavService } from '../../../_services/route.service';
import { User } from '../../../_models/User';
import { CompanyDialog } from '../../common/popups/company-popup/company-popup.component'
import { LoaderService } from '../../../_services/loader.service';
import { CategoriesAction } from '../../../_models/CategoriesAction';
import { MatSnackBar } from '@angular/material';
import { CalendarPopup } from '../../common/popups/calendar-popup/calendar-popup.component';

@Component({
  selector: 'app-assignment-ind',
  templateUrl: './assignment-ind.component.html',
  styleUrls: ['./assignment-ind.component.css']
})
export class AssignmentIndComponent implements OnInit {

  ScreenName = '給与・賞与'
  form: FormGroup
  isAdmin: boolean
  arrUser: User[] = [];
  strTime: string;
  mySubscription: any;
  arrCategories: CategoriesAction[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private topNav: TopNavService,
    private accountService: AuthService,
    private snackBar: MatSnackBar,
    private loader: LoaderService,
  ) {
    this.isAdmin = accountService.CheckPermissionUser()
  }
  ngOnInit() {
    this.initCategories();
  }

  initCategories() {
    this.loader.Loading.next(true);
    try {
      this.accountService.getCategories().subscribe(result => {
        if (result.status == 200 && result.data.length > 0) {
          this.arrCategories = result.data;
        }
        this.loader.Loading.next(false);
      });
    } catch (e) {
      console.log(e);
      this.loader.Loading.next(false);
    }
  }

  executeAction(category: CategoriesAction) {
    this.topNav.categories.next(category);
    this.topNav.typeOpenCompanyPopup.next(category.categoriesID);
    if (category.typeSend == 2) {
      if (category.categoriesID == 6) {
        // send group
        let dialogRef = this.dialog.open(CalendarPopup);
        dialogRef.afterClosed().subscribe(data => {
          let filesData = data as FileList;
          if (filesData != null && filesData != undefined) {
            // 給与明細配布 (6)
            this.tax(filesData, category.categoriesID);
          }
        });
      } else {
        // send group
        let dialogRef = this.dialog.open(CompanyDialog);
        dialogRef.afterClosed().subscribe(data => {
          let fileData = data as File;
          if (fileData != null && fileData != undefined) {
            if (category.categoriesID == 1 || category.categoriesID == 2) {
              // 給与明細配布 (1)
              // 賞与明細配布 (2)
              this.salaryAndBonus(fileData, category.categoriesID);
            }
            else if (category.categoriesID == 3) {
              //社員ID追加 (3)
              this.importUser(fileData);
            }
          }
        });
      }

    }
    else if (category.typeSend == 1) {
      // send user
      if (category.categoriesID == 5) {
        // 配布ファイル管理 (4)
        // 源泉徴収票   (5)
        this.openScreenListUser(category.categoriesID);
      }
      if (category.categoriesID == 4) {
        this.router.navigateByUrl(`/admin/salary/list`);
      }
    }
    //var dialogRef = this.dialog.open(CompanyDialog);
    //dialogRef.afterClosed().subscribe(data => {
    //  const fileData = data as File;
    //  if (category.categoriesID == 1 || category.categoriesID == 2) {
    //    // 給与明細配布 (1)
    //    // 賞与明細配布 (2)
    //    this.salaryAndBonus(fileData, category.categoriesID);
    //  }
    //  else if (category.categoriesID == 3) {
    //    //社員ID追加 (3)
    //    this.importUser(fileData);
    //  }
    //  else if (category.categoriesID == 4 || category.categoriesID == 5) {
    //    // 配布ファイル管理 (4)
    //    // 源泉徴収票   (5)
    //    // typesend = user.
    //    this.openScreenListUser(category.categoriesID);
    //  }
    //})
  }

  //openDialog(typeSelect: number) {
  //  this.topNav.typeOpenCompanyPopup.next(typeSelect);
  //  var dialogRef = this.dialog.open(CompanyDialog);
  //  dialogRef.afterClosed().subscribe(data => {
  //    const fileData = data as File;
  //    if (typeSelect === 3) {
  //      //import file data user
  //      this.importUser(fileData);
  //    }
  //    else {
  //      //import file salary and bonus
  //      this.salaryAndBonus(fileData, typeSelect);
  //    }
  //  })
  //}

  async salaryAndBonus(fileSelect: File, selectType: number) {
    if (fileSelect && fileSelect != undefined) {
      this.topNav.timeSelect.subscribe(value => this.strTime = value);
      this.accountService.setTimeUploadFile(this.strTime);
      this.loader.Loading.next(true);
      const formData: FormData = new FormData();
      formData.append('file', fileSelect, fileSelect.name);
      formData.append('time', this.strTime);
      formData.append('selectType', selectType.toString());
      let compCode = this.accountService.getCompanyCode();
      formData.append('compCode', compCode);
      await this.accountService.splitFileSalary(formData).subscribe(result => {
        this.loader.Loading.next(false);
        if (result && result.data) {
          this.topNav.dataTransfer.next(result.data);
          this.router.navigateByUrl(`/admin/salary/${compCode}/${this.strTime.substr(0, 6)}/${selectType}/0`);
        }
        else {
          this.snackBar.open('アップロード データ入力に失敗。', null, { duration: 3000 })
        }
      });
    }
  }

  // 源泉徴収票   (5)
  sendFileWithholding(fileSelect: File) {
    if (fileSelect && fileSelect != undefined) {
      this.loader.Loading.next(true);
      const formData: FormData = new FormData();
      formData.append('file', fileSelect, fileSelect.name);
      formData.append('selectType', '5');
      this.accountService.splitFileSalary(formData).subscribe(result => {
        this.loader.Loading.next(false);
        if (result && result.data) {
          this.topNav.dataTransfer.next(result.data);
          this.router.navigateByUrl('/admin/salary');
        }
        else {
          this.snackBar.open('アップロード データ入力に失敗。', null, { duration: 3000 })
        }
      });
    }
  }

  async importUser(file: File) {
    if (file != null && file != undefined) {
      this.loader.Loading.next(true);
      var companySelect = 1;
      this.topNav.companySelect.subscribe(value => companySelect = value);
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('companyIndex', companySelect.toString());
      await this.accountService.importUserCSV(formData).subscribe(result => {
        this.loader.Loading.next(false);
        if (result && result.data) {
          this.topNav.arrUserImport.next(result.data);
          this.router.navigateByUrl('/admin/import');
        }
        else {
          this.snackBar.open('会員データ入力に失敗。', null, { duration: 3000 })
        }
      });
    }
  }


  async tax(filesSelect: FileList, selectType: number) {
    if (filesSelect && filesSelect != undefined) {
      this.topNav.timeSelect.subscribe(value => this.strTime = value);
      this.accountService.setTimeUploadFile(this.strTime);
      this.loader.Loading.next(true);
      const formData: FormData = new FormData();

      for (let i = 0; i < filesSelect.length; i++) {
        formData.append('file', filesSelect[i], filesSelect[i].name);
      }
      formData.append('time', this.strTime);
      formData.append('selectType', selectType.toString());
      await this.accountService.splitFileSalary(formData).subscribe(result => {
        this.loader.Loading.next(false);
        if (result && result.data) {
          this.topNav.dataTransfer.next(result.data);
          this.router.navigateByUrl(`/admin/tax/${this.strTime.substr(0, 6)}/${selectType}/0`);
        }
        else {
          this.snackBar.open('アップロード データ入力に失敗。', null, { duration: 3000 })
        }
      });
    }
  }


  openScreenListUser(categoryId: number) {
    this.topNav.typeOpenScreenUser.next(categoryId);
    this.router.navigateByUrl("/admin/user");
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
