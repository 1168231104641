import { Component, OnInit } from '@angular/core';
import { GroupApiService } from 'src/app/_services/group.api.service';
import { GroupRole } from 'src/app/_models/GroupRole';
import { EditMemberDialog, EditMemberData } from './edit-member/edit-member.component';
import { MatDialog } from '@angular/material/dialog';
import { Member } from 'src/app/_models/Member';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { LoaderService } from 'src/app/_services/loader.service';
import { TopNavService } from '../../../_services/route.service';

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.css']
})
export class GroupAddComponent implements OnInit {

  ScreenName: string = "グループ追加"

  Users: Member[] = []
  GroupRoles: GroupRole[] = []
  Members: Member[] = []
  groupId: string
  allowSubmit: boolean = false;

  form: FormGroup

  constructor(
    private topNav: TopNavService,
    private apiService: GroupApiService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private overlay: Overlay,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private router: Router,
    private loader: LoaderService
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.groupId = params.get('groupId')
      if (this.groupId) this.ScreenName = 'グループ編集'
    })
    
    this.GetCreateGroup();

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength]],
      description: ['']
    })
  }

  async GetCreateGroup() {
    var result = await this.loader.Forward(this.apiService.GetCreateGroup(this.groupId), true, true).toPromise();
    if (result.status === 200) {
      this.Users = result.data.users;
      this.GroupRoles = result.data.groupRoles;
      this.Members = result.data.members || [];
      if (result.data.group) {
        this.form.patchValue({
          name: result.data.group.name,
          description: result.data.group.description
        });
      }
    }
  }

  async OnSubmit() {
    if (!this.Validate()) {
      this.snackbar.open('必須項目をもう一度を確認してください。', '', { duration: 5000 });
      return false;
    }
    var data = {
      group: {
        id: this.groupId,
        name: this.form.value.name,
        description: this.form.value.description
      },
      users: this.Members.map(m => {
        return { userId: m.id, groupRoleId: m.groupRoleId }
      })
    }
    var result;
    if (this.groupId) {
      result = await this.apiService.EditGroup(this.groupId, data).toPromise()
    } else {
      result = await this.apiService.CreateNewGroup(data).toPromise()
    }
    if (result.status === 200) {
      this.router.navigateByUrl('/admin/group')
    }
  }

  Validate() {
    if (!this.form.valid) return false;

    var requiredRoles = this.GroupRoles.filter(r => r.isRequired)
    return requiredRoles.every(r => this.Members.some(m => m.groupRoleId == r.id))
  }

  EditMember(id) {
    var data: EditMemberData = {
      id: id,
      groupRoles: this.GroupRoles,
      users: this.Users.clone(),
      members: this.Members.clone()
    }
    const dialogRef = this.dialog.open(EditMemberDialog, {
      scrollStrategy: this.overlay.scrollStrategies.block(),
      minWidth: '80vw',
      maxWidth: '90vw',
      maxHeight: '100vh',
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isSave) {
        this.Members = result.data.members;
        this.Users = result.data.users;
        this.allowSubmit = true;
      }
    });
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
