import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class DeviceService implements OnDestroy {
  public IsMobile: BehaviorSubject<boolean>

  private mobile: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    media: MediaMatcher,
  ) {
    this.mobile = media.matchMedia('(max-width: 968px)');
    this._mobileQueryListener = () => {
      this.IsMobile.next(this.mobile.matches)
      //changeDetectorRef.detectChanges()
    }
    this.mobile.addListener(this._mobileQueryListener);
    this.IsMobile = new BehaviorSubject<boolean>(this.mobile.matches)
  }

  ngOnDestroy(): void {
    this.mobile.removeListener(this._mobileQueryListener);
  }
}
