import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserApiService } from 'src/app/_services/user.api.service';
import { User } from 'src/app/_models/User';
import { LoaderService } from 'src/app/_services/loader.service';
import { MyGroup } from '../../../_models/MyGroup';
import { ClassProviderService } from '../../../_services/class-provider.service';
import { MatTableDataSource, MatSlideToggleChange } from '@angular/material';
import { TopNavService } from '../../../_services/route.service';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {
  
  ScreenName: string
  User: User
  Groups: MyGroup[]
  UserId: string

  columnIds = ['groupname', 'role']
  dataSource: MatTableDataSource<MyGroup>

  constructor(
    private topNav: TopNavService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: UserApiService,
    private loader: LoaderService,
    private accountService: AuthService,
    private clazz: ClassProviderService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.UserId = params.get('userId');
      await this.GetUser();
    })
  }

  async GetUser() {
    var result = await this.loader.Forward(this.apiService.GetUser(this.UserId), true, true).toPromise();
    if (result.status === 200) {
      this.User = result.data.user
      this.Groups = result.data.groups
      this.ScreenName = this.User.name
      this.dataSource = new MatTableDataSource<MyGroup>(this.Groups);
    }
  }

  UpdateStatus(event: MatSlideToggleChange) {
    this.loader.Forward(this.apiService.UpdateStatus(this.User.id, event.checked ? 1 : 2)).subscribe(result => {
      if (result.status === 200) {
        this.User.status = result.data
      } else {
        event.source.checked = !event.source.checked 
      }
    })
  }

  GetUserStatusClass(status: string) {
    return this.clazz.GetUserStatusClass(status)
  }

  openUpdate(userId: number) {
    this.accountService.setStatusUpdateUser('0');
    this.topNav.dataTransfer.next(userId.toString());
    this.router.navigateByUrl('/admin/update');
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
