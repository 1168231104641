import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }

  GetNotifications(sort: string, size: number, offset: number) {
    return this.http.get<any>(this.baseUrl + `api/notification/get?sort=${sort}&offset=${offset}&size=${size}`)
  }

  ReadNotification(id: number) {
    return this.http.put<any>(this.baseUrl + 'api/notification/read/' + id, null)
  }
  CountUnread() {
    return this.http.get<any>(this.baseUrl + 'api/notification/countUnread')
  }
  GetCategories() {
    return this.http.get<any>(this.baseUrl + 'api/notification/category')
  }
  SendNotify(data: any) {
    return this.http.post<any>(this.baseUrl + 'api/notification/send', data)
  }
}
