import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthResult } from '../_models/AuthResult';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private User: AuthUser

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    var token = localStorage.getItem('token')
    if (token) {
      this.User = new AuthUser(token)
    }
  }

  ChangePassword(data: any) {
    return this.http.post<AuthResult>(this.baseUrl + 'api/account/change-password', data).pipe(
      map(res => {
        if (res.status === 200) {
          this.SaveSession(res);
        }
        return res;
      })
    );
  }

  ChangePasswordViewFile(newPass: string) {
    const formData: FormData = new FormData();
    formData.append('newPass', btoa(newPass));
    var result = this.http.post<boolean>(this.baseUrl + 'api/account/password-file', formData);
    if (result) {
      localStorage.setItem('passViewFile', newPass);
    }
    return result;
  }

  setStatusUploadFile(status: string) {
    localStorage.setItem('statusUpload', status);
  }

  getStatusUploadFile() {
    var status = localStorage.getItem('statusUpload');
    if (this.isEmptyOrNull(status) || status == '0') {
      return false;
    }
    else {
      return true;
    }
  }

  setStatusOpenFile(statusOpen: string) {
    localStorage.setItem('statusOpenFile', statusOpen);
  }

  getStatusOpenFile() {
    let result = false;
    var statusOpen = localStorage.getItem('statusOpenFile');
    if (!this.isEmptyOrNull(statusOpen) && statusOpen == '1') {
      result = true;
    }
    return result;
  }

  setTimeUploadFile(time: string) {
    localStorage.setItem('timeUpload', time);
  }

  getTimeUploadFile() {
    var time = localStorage.getItem('timeUpload');
    if (!this.isEmptyOrNull(time)) {
      return time
    }
    else {
      return '';
    }
  }

  setStatusUpdateUser(statusUpdate: string) {
    localStorage.setItem('statusUpdate', statusUpdate);
  }

  getStatusUpdateUser() {
    var statusUpdate = localStorage.getItem('statusUpdate');
    if (!this.isEmptyOrNull(statusUpdate)) {
      if (statusUpdate == '1') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  setCompanyCode(compCode: string) {
    localStorage.setItem('compCode', compCode);
  }

  getCompanyCode() {
    var compCode = localStorage.getItem('compCode');
    if (!this.isEmptyOrNull(compCode)) {
      return compCode
    }
    else {
      return '';
    }
  }

  GetAllFileInfo(typeGet: number, userId: number, page: number, size: number) {
    const formData: FormData = new FormData();
    formData.append('typeGet', typeGet.toString());
    if (userId > 0) {
      formData.append('userId', userId.toString());
    }
    else {
      formData.append('userId', this.GetCurrentUserId().toString());
    }
    formData.append('page', page.toString());
    formData.append('size', size.toString());
    var result = this.http.post<any>(this.baseUrl + 'api/account/salary', formData);
    return result;
  }

  GetAllFileError() {
    let compCode = this.getCompanyCode();
    if (compCode) {
      let formData: FormData = new FormData();
      formData.append('compCode', compCode);
      var result = this.http.post<any>(this.baseUrl + 'api/account/error', formData);
      return result;
    }
    else {
      return null;
    }
  }

  GetAllSalaryByType(type: string, comcd: string, time: string, isPublish: string) {
    var formData = new FormData();
    formData.append('type', type);
    formData.append('time', time);
    formData.append('isPublish', isPublish);
    //let compCode = this.getCompanyCode();
    if (comcd || type === "6") {
      formData.append('compCode', comcd ? comcd : "");
      var result = this.http.post<any>(this.baseUrl + 'api/account/allSplit', formData);
      return result;
    }
    else {
      return null;
    }
  }

  PublishSalaryAndBonus(type: string, comcd: string, time: string) {
    var formData = new FormData();
    formData.append('type', type);
    formData.append('time', time);
    formData.append('compCode', comcd ? comcd : "");
    var result = this.http.post<any>(this.baseUrl + 'api/account/salary/publish', formData);
    return result;
  }

  GetTimeSplitFile(type: string, compCd: string, isPublish: string) {
    var formData = new FormData();
    formData.append('type', type.toString());
    formData.append('isPublish', isPublish);
    formData.append('compCode', compCd ? compCd : "");
    var result = this.http.post<any>(this.baseUrl + 'api/account/split/all', formData);
    return result;
  }

  GetTimeSplitFileByEmplCd(compCd: string, emplCd: string) {
    var formData = new FormData();
    formData.append('emplCode', emplCd);
    formData.append('compCode', compCd);
    var result = this.http.post<any>(this.baseUrl + 'api/account/split/empl', formData);
    return result;
  }

  LoginAsAdmin(username: string, password: string) {
    return this.http.post<AuthResult>(this.baseUrl + 'api/account/login/admin', { username, password }).pipe(
      map(res => {
        if (res.status === 200) {
          this.SaveSession(res);
        }
        return res;
      })
    );
  }

  LoginAsUser(username: string, password: string) {
    return this.http.post<AuthResult>(this.baseUrl + 'api/account/login/user', { username, password }).pipe(
      map(res => {
        if (res.status === 200) {
          this.SaveSession(res);
        }
        return res;
      })
    );
  }

  Logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expired');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('passViewFile');
  }

  RemoveToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('expired');
  }

  RefreshToken() {
    var refreshToken = localStorage.getItem('refreshToken');
    return this.http.get<AuthResult>(this.baseUrl + 'api/account/token', { headers: { RefreshToken: `Bearer ${refreshToken}` } })
  }

  CheckUserIsAdmin() {
    return this.User.IsAdmin()
  }

  CheckPermissionUser() {
    return this.User.CheckPermission();
  }

  CheckUserIsLogin() {
    var token = localStorage.getItem('token');
    return token;
  }

  SaveSession(authResult: AuthResult) {
    localStorage.setItem('token', authResult.data.accessToken);
    localStorage.setItem('expired', '' + authResult.data.expiredTime);
    localStorage.setItem('refreshToken', authResult.data.refreshToken);
    if (this.isEmptyOrNull(authResult.data.passViewFile)) {
      localStorage.setItem('passViewFile', '');
    }
    else {
      localStorage.setItem('passViewFile', atob(authResult.data.passViewFile));
    }
    this.User = new AuthUser(authResult.data.accessToken);
  }

  SaveReSession(authResult: AuthResult) {
    localStorage.setItem('token', authResult.data.accessToken);
    localStorage.setItem('expired', '' + authResult.data.expiredTime);
    this.User = new AuthUser(authResult.data.accessToken);
  }

  GetPassViewFile() {
    const passViewFile = localStorage.getItem('passViewFile');
    if (this.isEmptyOrNull(passViewFile)) {
      return '';
    }
    return passViewFile;
  }

  isEmptyOrNull(strCheck: string) {
    if (strCheck == null || strCheck == undefined || strCheck == 'null' || strCheck.length == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  GetCurrentUserId(): number {
    return this.User.GetCurrentUserId()
  }
  GetUserName(): string {
    return this.User.GetUserName()
  }
  GetMemberAuthority(groupId: number): string[] {
    return this.User.GetMemberAuthority(groupId)
  }
  GetDailyAuthority(groupId: number): string[] {
    return this.User.GetDailyAuthority(groupId)
  }
  GetWeeklyAuthority(groupId: number): string[] {
    return this.User.GetWeeklyAuthority(groupId)
  }

  /**
   * tien.tranvan
   * */

  importUserCSV(formData: FormData) {
    return this.http.post<any>(this.baseUrl + 'api/account/importUser', formData);
  }

  splitFileSalary(formData: FormData) {
    return this.http.post<any>(this.baseUrl + 'api/account/split', formData);
  }

  splitFileSalary2(formData: FormData) {
    return this.http.post<any>(this.baseUrl + 'api/account/splits', formData);
  }

  splitFileTax(formData: FormData) {
    return this.http.post<any>(this.baseUrl + 'api/account/split', formData);
  }

  updateSalaryInfo(employeecode: string, id: number, compCd: string) {
    return this.http.post<any>(this.baseUrl + 'api/account/salary/update', { employeecode, id, compCd });
  }

  deleteFile(id: number) {
    return this.http.post<any>(this.baseUrl + 'api/account/salary/delete', { id });
  }

  deleteAllFile(compCd: string, type: string, time: string) {
    var formData = new FormData();
    formData.append('compCd', compCd ? compCd : "");
    formData.append('fileType', type);
    formData.append('strTime', time);
    return this.http.post<any>(this.baseUrl + 'api/account/salary/deleteAll', formData);
  }

  downloadFile(id: number) {

    return this.http.request<Blob>(new HttpRequest(
      'GET',
      `${this.baseUrl + 'api/account/salary/download'}/${id}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }))

    //return this.http.post<any>(this.baseUrl + 'api/account/salary/download', { id }, { responseType: 'arraybuffer' }).pipe(
    //  map(
    //    (res) => {
    //      var blob = new Blob([res.blob()], { type: fileType })
    //      return blob;
    //    })
    //);
  }

  viewSalary(id: number) {
    var formData = new FormData();
    formData.append('salaryId', id.toString());
    return this.http.post<string>(this.baseUrl + 'api/account/salary/detail', formData);
  }

  getUrlFile(formData: FormData) {
    return this.http.post<string>(this.baseUrl + 'api/account/salary/view', formData);
  }

  getAllTimeSplitFile() {
    return this.http.get<any>(this.baseUrl + 'api/account/salary/alltime');
  }

  setUserViewHistory(userId: number) {
    localStorage.setItem('userId', userId.toString());
  }

  getUserViewHistory(): number {
    let userId = localStorage.getItem('userId');
    if (this.isEmptyOrNull(userId)) {
      return 0;
    }
    else {
      return +userId;
    }
  }

  setUserSelected(user: User) {
    localStorage.setItem('user', user.name + '-' + user.id);
  }

  getUserSelected(): string {
    let user = localStorage.getItem('user');
    if (this.isEmptyOrNull(user)) {
      return '';
    }
    else {
      return user;
    }
  }

  getCategories() {
    return this.http.get<any>(this.baseUrl + 'api/account/categories');
  }

  sendFile(formData: FormData) {
    return this.http.post<any>(this.baseUrl + 'api/account/send-file', formData);
  }

  countFileUnRead() {
    return this.http.get<any>(this.baseUrl + 'api/account/count-file');
  }

  updateReadFile(salaryId: number) {
    var formData = new FormData();
    formData.append('salaryId', salaryId.toString());
    return this.http.post<any>(this.baseUrl + 'api/account/read', formData);
  }

  /**
   *
   * */

}

class AuthUser {
  private userId: number
  private userName: string
  private userRole: string
  private groupRole: {
    groupId: number,
    authority: {
      member: string[],
      daily: string[],
      weekly: string[],
    }
  }[]

  constructor(token: string) {
    var obj = jwt_decode(token)
    this.userId = +obj.userId
    this.userName = obj.userName
    this.userRole = obj.userRole
    this.groupRole = Object.keys(obj).filter(k => k.startsWith('group_')).map(k => {
      var groupId = +k.substring('group_'.length);
      var value = JSON.parse(obj[k])
      var member = ((value.find(x => x.Type === "Member") || {}).Value || '').split(',')
      var daily = ((value.find(x => x.Type === "Daily") || {}).Value || '').split(',')
      var weekly = ((value.find(x => x.Type === "Weekly") || {}).Value || '').split(',')
      return {
        groupId,
        authority: {
          member,
          daily,
          weekly
        }
      }
    })
  }
  GetCurrentUserId() {
    return this.userId
  }
  IsAdmin() {
    return (this.userRole === "管理者")
  }
  CheckPermission() {
    return (this.userRole === "管理者" || this.userRole === "経理")
  }
  GetUserName() {
    return this.userName
  }
  GetMemberAuthority(groupId: number) {
    var auth = this.groupRole.find(g => g.groupId === groupId)
    return auth ? auth.authority.member : []
  }
  GetDailyAuthority(groupId: number) {
    var auth = this.groupRole.find(g => g.groupId === groupId)
    return auth ? auth.authority.daily : []
  }
  GetWeeklyAuthority(groupId: number) {
    var auth = this.groupRole.find(g => g.groupId === groupId)
    return auth ? auth.authority.weekly : []
  }
}
