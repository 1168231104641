import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production || environment.testing) {
  enableProdMode();
}
window.onload = () => {
  var script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaId}`
  script.async = true

  var script2 = document.createElement('script')
  script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', '${environment.gaId}');
`
  document.body.append(script)
  document.body.append(script2)
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
