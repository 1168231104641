import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoriesAction } from '../_models/CategoriesAction';

@Injectable({ providedIn: 'root' })
export class TopNavService {
  public leftButton = new BehaviorSubject<TopButtonBehavior>(null)
  public centerDisplay = new BehaviorSubject<DisplayBehavior>(null)
  public rightButton = new BehaviorSubject<TopButtonBehavior>(null)
  public unreadMessage = new BehaviorSubject<number>(0)
  public arrUserImport = new BehaviorSubject<any>(null)
  public typeOpenCompanyPopup = new BehaviorSubject<number>(0)
  public companySelect = new BehaviorSubject<number>(0)
  public timeSelect = new BehaviorSubject<string>("");
  public linkFile = new BehaviorSubject<string>("");
  public dataTransfer = new BehaviorSubject<any>(null);
  public lstSalaryError = new BehaviorSubject<any>(null);
  public lstSalarySucc = new BehaviorSubject<any>(null);
  public isUploadFile = new BehaviorSubject<boolean>(false);
  public typeOpenScreenUser = new BehaviorSubject<number>(0);
  public categories = new BehaviorSubject<CategoriesAction>(null);
  public userIdSelected = new BehaviorSubject<number>(0);

  constructor() {
  }
  Reset() {
    this.leftButton.next(null)
    this.rightButton.next(null)
    this.centerDisplay.next(null)
  }
}

export interface TopNavBehavior {
  onLeftClick(Event)
  onRightClick(Event)
}

export interface TopButtonBehavior {
  id?: string,
  routerLink?: string,
  icon: string,
}
export interface DisplayBehavior {
  text: string,
  class?: string,
  style?: string,
}
