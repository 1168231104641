import { Component, OnInit } from '@angular/core'
import { User } from 'src/app/_models/User'
import { MatTableDataSource } from '@angular/material'
import { TopNavService } from '../../../_services/route.service'
import { NavigationEnd, Router } from '@angular/router'

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styles: ['.full-width-input {width: 100%}']
})
export class ImportUsersComponent implements OnInit {

  ScreenName: string = '社員ID追加情報'
  Users: User[] = []
  dataSource: MatTableDataSource<User>
  columnIds = ['employeeCode', 'name', 'status']
  user: User

  constructor(
    private router: Router,
    private topNav: TopNavService
  ) {
    topNav.arrUserImport.subscribe(value => this.Users = value);
    this.dataSource = new MatTableDataSource<User>(this.Users);
  }

  ngOnInit() {
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
