import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'jpDate'
})
export class JpDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format: string): any {
    const days = [
      '（日）',
      '（月）',
      '（火）',
      '（水）',
      '（木）',
      '（金）',
      '（土）',
    ];

    if (!value || !format) return value
    var dateFormat = super.transform(new Date(value), format)
    var date = new Date(value)
    return dateFormat + days[date.getDay()]
  }

}
