import { RouterModule, Routes } from "@angular/router";
import { UserLoginLayoutComponent } from '../_layout/user/user-login-layout/user-login-layout.component';
import { UserLoginComponent } from 'src/app/_component/user/login/login.component';
import { UserMainLayoutComponent } from '../_layout/user/user-main-layout/user-main-layout.component';
import { MyDailyListComponent } from '../_component/user/my-daily-list/my-daily-list.component';
import { MyWeeklyListComponent } from '../_component/user/my-weekly-list/my-weekly-list.component';
import { MyInfoComponent } from '../_component/user/my-info/my-info.component';
import { NotificationComponent } from '../_component/user/notification/notification.component';
import { MyGroupComponent } from '../_component/user/my-group/my-group.component';
import { UserGroupDetailComponent } from '../_component/user/user-group-detail/user-group-detail.component';
import { AuthGuard } from '../_auth/auth.guard';
// tien.tranvan
import { SalaryBonusComponent } from '../_component/user/salary-bonus/salary-bonus.component';
import { ListSalaryClientComponent } from '../_component/user/salary-list-client/salary-list-client.component';

const userRoutes: Routes =
  [
    {
      path: '', component: UserMainLayoutComponent, canActivate: [AuthGuard] ,children: [
        { path: '', pathMatch: 'full', redirectTo: 'notification' },
        { path: 'daily', component: MyDailyListComponent, data: { num: 2 } },
        { path: 'weekly', component: MyWeeklyListComponent, data: { num: 5 } },
        { path: 'information', component: MyInfoComponent, data: { num: 7 } },
        { path: 'notification', component: NotificationComponent, data: { num: 9 } },
        { path: 'group', component: MyGroupComponent, data: { num: 0 } },
        { path: 'group/:groupId', component: UserGroupDetailComponent, data: { num: 1 } },
        // tien.tranvan
        { path: 'assignment', component: SalaryBonusComponent },
        { path: 'files', component: ListSalaryClientComponent },
      ]
    },
    {
      path: '', component: UserLoginLayoutComponent, children: [
        { path: 'login', component: UserLoginComponent },
      ]
    },
  ]
export const UserRoutes = RouterModule.forRoot(userRoutes);
