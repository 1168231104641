import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../../../_services/loader.service';
import { DailyReportService } from '../../../_services/daily-report.service';
import { UserReport } from '../../../_models/DailyReport';
import { DeviceService } from '../../../_services/device.service';
import { MatDialog, MatCalendarCellCssClasses } from '@angular/material';
import { ReviewDailyDialog } from './review-daily/review-daily.component';
import { isError } from 'util';
import { ClassProviderService } from '../../../_services/class-provider.service';

@Component({
  selector: 'app-daily-group',
  templateUrl: './daily-group.component.html',
  styleUrls: ['./daily-group.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DailyGroupComponent implements OnInit {
  @Input() groupId: number
  reportDate: Date
  timeGetData: Date
  reports: { userId: number, name: string, report: UserReport, isReminded: boolean }[]
  isMobile: boolean

  constructor(
    private apiService: DailyReportService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private clazz: ClassProviderService,
    device: DeviceService
  ) {
    device.IsMobile.subscribe(value => this.isMobile = value)
  }

  ngOnInit() {
    this.Initialize()
  }

  async Initialize(requestDate?: Date, isLoading: boolean = false) {
    var result = await this.loader.Forward(this.apiService.GetGroupDailyReports(this.groupId, requestDate), isLoading, true).toPromise()
    if (result.status === 200) {
      this.reportDate = new Date(result.data.reportDate)
      this.reports = result.data.reports
    }
  }
  ChangeDate(offset: number) {
    this.timeGetData = this.reportDate;
    this.timeGetData.setDate(this.reportDate.getDate() + offset);
    //this.reportDate.setDate(this.reportDate.getDate() + offset)
    console.log(this.timeGetData);
    this.Initialize(this.timeGetData, true)
  }
  GoDetail(reportId: number) {
    var dialogRef = this.dialog.open(ReviewDailyDialog, {
      panelClass: ['no-pad-dialog', this.isMobile ? 'sp' : 'pc'],
      data: {
        reportId: reportId,
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isSaveSuccess) {
        this.Initialize(this.reportDate)
      }
    })
  }
  RequestReport(userId: number) {
    this.loader.Forward(this.apiService.RequestReport(userId, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.reports.find(r => r.userId === userId).isReminded = true
      }
    })
  }

  GetReportStatusClass(statusId: number) {
    return this.clazz.GetReportStatusClass(statusId)
  }

  dateClass = (d: Date): MatCalendarCellCssClasses => {
    return d.getDay() == 0 ? 'sunday' : d.getDay() == 6 ? 'saturday' : ''
  }
}
