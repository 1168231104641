import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateFileInfoPopup } from '../update-file-info/update-file-info.component';
import { PDFDocumentProxy, PDFProgressData } from 'pdfjs-dist';
import { LoaderService } from '../../../../_services/loader.service';
import { TopNavService } from '../../../../_services/route.service';
import { Salary } from '../../../../_models/Salary';
import { AuthService } from '../../../../_services/auth.service';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { DeviceService } from '../../../../_services/device.service';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.css']
})
export class PreviewFilePopup implements OnInit {
  src = '';
  canUpdate: boolean;
  data: Salary;
  pathFilePdf: string;
  inprogress: boolean = false;
  isComplete = false
  isPublish: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inData: { isPublish: boolean, isConfirm: boolean },
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PreviewFilePopup>,
    private topNav: TopNavService,
    private authService: AuthService,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
  ) {
    this.topNav.linkFile.subscribe(link => {
      this.pathFilePdf = link;
      // TODO
      //if (inData != null) {
      //  this.isPublish = inData.isPublish
      //}
    });
  }

  ngOnInit() {
    try {
      let inprogress = this.authService.getStatusOpenFile();
      if (!inprogress) {
        console.log('OPEN');
        this.authService.setStatusOpenFile('1');
        this.topNav.dataTransfer.subscribe(value => {
          this.data = value;
          if (this.data && this.data.status == 1) {
            this.canUpdate = true;
          }
        });
        this.src = this.pathFilePdf;
      }
      else {
        console.log('NOT OPEN');
      }
    } catch (error) {
      console.log('ERROR ABC');
    }

  }

  inputEmployeeCd() {
    const dialogUpdate = this.dialog.open(UpdateFileInfoPopup);
    dialogUpdate.afterClosed().subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  callBackFn(pdf: PDFDocumentProxy) {
    console.log('CALL BACK');
    this.loader.Loading.next(false);
    this.isComplete = true
  }

  onError(error: any) {
    console.log('ERROR');
    this.loader.Loading.next(false);
    this.dialogRef.close(null);
    this.snackBar.open('ファイルを開くことができませんでした。', null, { duration: 3000 })
  }

  pageRendered(e: CustomEvent) {
    console.log('page Rendered');
  }

  onProgress(progressData: PDFProgressData) {
    console.log('On progress');
  }

  DownloadFile() {
    try {
      this.snackBar.open("バックグラウンドでダウンロード中", null, { duration: 3000 })
      this.authService.downloadFile(this.data.salaryID).subscribe(data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            console.log(`${data.loaded}/${data.total}`)
            break
          case HttpEventType.Response:
            var downloadFileContent = new Blob([data.body], { type: data.body.type })
            const a = document.createElement('a')
            a.setAttribute('style', 'display:none;')
            document.body.appendChild(a)
            a.download = this.data.nameFile
            a.href = URL.createObjectURL(downloadFileContent)
            a.target = '_blank'
            a.click()
            document.body.removeChild(a)
            break
        }
      }, err => {
        console.log(err)
      })
    } catch (e) {
      console.log(e)
    }
  }
}
