import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/User';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  GetListUser(page: number, size: number) {
    return this.http.get<any>(this.baseUrl + `api/user?page=${page}&size=${size}`)
  }
  GetUser(userId: number | string) {
    return this.http.get<any>(this.baseUrl + 'api/user/' + userId)
  }
  GetMyInfo() {
    return this.http.get<any>(this.baseUrl + 'api/user/info')
  }
  UpdateStatus(id: number, status: number) {
    return this.http.patch<any>(this.baseUrl + 'api/user/status/' + id, { statusId: status })
  }

  GetEmployee(compCd: string) {
    return this.http.get<any>(this.baseUrl + `api/user/employee/${compCd}`);
  }

  // tien.tranvan
  UpdateUser(data: User) {
    return this.http.put<any>(this.baseUrl + 'api/user/update/' + data.id, data)
  }
  GetAllUser(page: number, size: number, type: number) {
    return this.http.get<any>(this.baseUrl + `api/user/all?page=${page}&size=${size}&type=${type}`)
  }
}
