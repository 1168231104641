import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Group } from '../../../../_models/Group';
import { GroupApiService } from '../../../../_services/group.api.service';
import { LoaderService } from '../../../../_services/loader.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialog implements OnInit {
  isError = false
  constructor(
    @Inject(MAT_DIALOG_DATA) private group: Group,
    private apiService: GroupApiService,
    private loader: LoaderService,
    public dialogRef: MatDialogRef<DeleteDialog>,
  ) { }

  ngOnInit() {
  }

  DeleteGroup(groupName: string) {
    if (groupName === this.group.name) {
      this.isError = false
      this.loader.Forward(this.apiService.DeleteGroup(this.group.id)).subscribe(result => {
        if (result.status === 200) {
          this.dialogRef.close(true)
        }
      })
    } else {
      this.isError = true
    }
  }

}
