import { Component, OnInit } from '@angular/core'
import { fromEvent } from 'rxjs'
import { filter } from 'rxjs/operators'
import { LoaderService } from 'src/app/_services/loader.service'
import { Router, NavigationStart, NavigationEnd } from '@angular/router'
import { AuthService } from '../../../_services/auth.service'
import { TopNavService } from '../../../_services/route.service'

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  title = 'Sharaku'
  isAdmin: boolean

  constructor(
    private topNav: TopNavService,
    private loader: LoaderService,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    fromEvent(window, 'popstate')
      .pipe(
        filter(() => {
          return this.loader.Loading.value || this.loader.Error.value.error
        }),
      ).subscribe(() => {
        this.loader.Loading.next(false)
        this.loader.Error.next({ error: false })
        this.loader.Subscriptions.forEach(s => s.unsubscribe())
      })

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart && (this.loader.Loading.value || this.loader.Error.value.error))
      ).subscribe(() => {
        this.loader.Loading.next(false)
        this.loader.Error.next({ error: false })
        this.loader.Subscriptions.forEach(s => s.unsubscribe())
      })

    this.checkAdmin();
  }

  checkAdmin() {
    this.isAdmin = this.authService.CheckUserIsAdmin();
  }

  openInTab() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  Logout() {
    this.authService.Logout()
    this.router.navigateByUrl('/admin/login')
  }
}
