import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { UserReport } from '../../../../_models/DailyReport';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DailyReportService } from '../../../../_services/daily-report.service';
import { LoaderService } from '../../../../_services/loader.service';

@Component({
  selector: 'app-review-daily',
  templateUrl: './review-daily.component.html',
  styleUrls: ['./review-daily.component.css']
})
export class ReviewDailyDialog implements OnInit {
  report: UserReport
  form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<ReviewDailyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private loader: LoaderService,
    private apiService: DailyReportService,
    fb: FormBuilder,
  ) {
    this.form = fb.group({
      reviewComment: ['', [Validators.maxLength(80)]]
    })
  }

  ngOnInit() {
    this.Initialize()
  }

  Initialize() {
    this.loader.Forward(this.apiService.GetDetail(this.data.reportId)).subscribe(result => {
      if (result.status === 200) {
        this.report = result.data
        this.form.controls.reviewComment.patchValue(result.data.reviewComment)
      }
    })
  }

  OnApprove() {
    if (!this.form.valid) return;
    var data = {
      id: this.data.reportId,
      reviewComment: this.form.value.reviewComment,
    }
    this.loader.Forward(this.apiService.ApproveReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    })
  }

  OnReject() {
    if (!this.form.valid) return;
    var data = {
      id: this.data.reportId,
      reviewComment: this.form.value.reviewComment,
    }
    this.loader.Forward(this.apiService.RejectReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    })
  }
}

interface DialogData {
  reportId: number,
}
