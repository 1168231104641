import { Pipe, PipeTransform } from '@angular/core';
import { Member } from '../_models/Member';

@Pipe({
  name: 'memberFilter'
})
export class MemberFilterPipe implements PipeTransform {

  transform(members: Member[], groupRoleId: number): Member[] {
    if (!members || !groupRoleId) return members;
    //members.map(m => m.hidden = !(m.groupRoleId === groupRoleId));
    //return members;
    return members.filter(m => m.groupRoleId === groupRoleId);
  }
}
