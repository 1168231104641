import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() isUserMode: boolean
  isLoading: boolean
  isMobile: boolean
  constructor(
    private loader: LoaderService,
  ) { }

  ngOnInit() {
    this.loader.Loading.subscribe(value => this.isLoading = value)
  }

}
