import { Component, ViewChild, ElementRef, OnInit } from '@angular/core'
import { UserApiService } from 'src/app/_services/user.api.service'
import { User } from 'src/app/_models/User'
import { MatTableDataSource, PageEvent, MatPaginatorIntl, MatSnackBar, MatDialog } from '@angular/material'
import { LoaderService } from 'src/app/_services/loader.service'
import { TopNavService } from '../../../_services/route.service'
import { Router, NavigationEnd } from '@angular/router'
import { AuthService } from '../../../_services/auth.service'
import { HelperService } from '../../../_services/helper.service'
import { ConfirmFilePopup } from '../../common/popups/confirm-file/confirm-file.component'
import { CategoriesAction } from '../../../_models/CategoriesAction'
import { CalendarPopup } from '../../common/popups/calendar-popup/calendar-popup.component'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styles: ['.full-width-input {width: 100%}']
})
export class UserListComponent implements OnInit {

  ScreenName: string = 'ユーザー一覧'
  Users: User[] = []
  @ViewChild('fileUpload', { static: false }) fileUploadButton: ElementRef;
  columnIds = ['id', 'employeeCode', 'name', 'kana', 'type', 'email', 'companyName', 'status', 'registed', 'lastlogin', 'lastactive', 'link']
  dataSource: MatTableDataSource<User>

  pageLength: number
  pageSize: number
  pageIndex: number
  pageSizeOptions = [25, 50, 100, 200]
  typeOpenScreen: number = 0;
  userIDSelected: number = 0;
  categoryAction: CategoriesAction;
  strTimeUpload: string;
  isDuplicateFile: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private topNav: TopNavService,
    private apiService: UserApiService,
    private loader: LoaderService,
    private helper: HelperService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    paginator: MatPaginatorIntl,
  ) {
    paginator.itemsPerPageLabel = "１ページあたり件数"
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) { return `合計${length}の0` }
      length = Math.max(length, 0)
      const startIndex = page * pageSize
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
      return `合計${length}の${startIndex + 1}～${endIndex}`
    }
  }

  ngOnInit() {
    this.typeOpenCurrentScreen();
    this.pageIndex = 0
    this.pageSize = this.pageSizeOptions[0]
    this.getConfig();
    if (this.typeOpenScreen != 0 && this.categoryAction !== null && this.categoryAction !== undefined) {
      // get user for view history send file.
      this.GetAllUser();
    }
    else {
      this.GetListUser();
    }
  }

  typeOpenCurrentScreen() {
    this.topNav.typeOpenScreenUser.subscribe(value => {
      this.typeOpenScreen = value;
    });
  }

  getConfig() {
    this.topNav.categories.subscribe(value => {
      this.categoryAction = value;
    });
    if (this.typeOpenScreen != 0) {
      this.ScreenName = this.categoryAction.screenName;
    }
    if (this.categoryAction && !this.categoryAction.isShowCalendar) {
      //this.helper.getAllTimeSplitFile();
    }
  }

  async GetListUser() {
    var result = await this.loader.Forward(this.apiService.GetListUser(this.pageIndex + 1, this.pageSize), true, true).toPromise()
    this.bindingDataUser(result);
  }

  async GetAllUser() {
    var result = await this.loader.Forward(this.apiService.GetAllUser(this.pageIndex + 1, this.pageSize, this.categoryAction.categoriesID), true, true).toPromise()
    this.bindingDataUser(result);
  }

  bindingDataUser(result: any) {
    if (result.status === 200) {
      this.Users = result.data.users
      this.pageLength = result.data.count
      this.dataSource = new MatTableDataSource<User>(this.Users)
    }
  }

  ChangePage(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex = 0
    } else {
      this.pageIndex = event.pageIndex
    }
    this.pageSize = event.pageSize
    if (this.typeOpenScreen != 0 && this.categoryAction !== undefined && this.categoryAction !== null) {
      this.loader.Forward(this.apiService.GetAllUser(this.pageIndex + 1, this.pageSize, this.categoryAction.categoriesID)).subscribe(result => {
        this.bindingDataUser(result);
      })
      return;
    }
    this.loader.Forward(this.apiService.GetListUser(this.pageIndex + 1, this.pageSize)).subscribe(result => {
      this.bindingDataUser(result);
      //if (result.status === 200) {
      //  this.Users = result.data.users
      //  this.pageLength = result.data.count
      //  this.dataSource = new MatTableDataSource<User>(this.Users)
      //}
    })
  }

  ApplyFilter(filter: string) {
    this.dataSource.filter = filter.trim().toLowerCase()
  }

  // view history
  viewHistory(userId: number) {
    this.authService.setUserViewHistory(userId);
    this.router.navigate(['admin/files'], { queryParams: { files: 'all' } });
  }

  // view user detail
  viewUserDetail(userId: number) {
    this.router.navigateByUrl('/admin/user/' + userId);
  }

  // after choose file
  async onFileSelectLocal(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      const fileUpload = files[0];
      let isPdf = this.helper.isPDF(fileUpload.name)
      if (!isPdf) {
        this.fileUploadButton.nativeElement.value = "";
        this.snackBar.open("フォーマットファイルが無効です。", null, { duration: 3000 })
      }
      else {
        // confirm send file
        if (this.isDuplicateFile) {
          this.upload(fileUpload);
        }
        else {
          let dialogRef = this.dialog.open(ConfirmFilePopup, {
            data: fileUpload.name
          });
          dialogRef.afterClosed().subscribe(actionNumber => {
            if (actionNumber == 1) {
              this.upload(fileUpload);
            }
          });
        }
      }
      //let timeSelected = new Date();
      //this.strTimeUpload = this.helper.getTime(timeSelected);
      //let strTimeSplit = this.helper.getTimeSplit(this.strTimeUpload);
      //let valid = this.validateFile(fileUpload.name, strTimeSplit);
      //if (valid == 1) {
      //  // unvalid type
      //  this.fileUploadButton.nativeElement.value = "";
      //  this.snackBar.open("フォーマットファイルが無効です。", null, { duration: 3000 })
      //}
      //else if (valid == 2) {
      //  // unvalid time
      //  let mess = this.helper.messConfirmDuplicateTime(this.categoryAction.categoriesID.toString(), strTimeSplit);
      //  if (confirm(mess)) {
      //    this.upload(fileUpload);
      //  }
      //}
      //else {
      //  // confirm send file
      //  let dialogRef = this.dialog.open(ConfirmFilePopup, {
      //    data: fileUpload.name
      //  });
      //  dialogRef.afterClosed().subscribe(actionNumber => {
      //    if (actionNumber == 1) {
      //      this.upload(fileUpload);
      //    }
      //  });
      //}
    }
  }

  // Update file to server
  upload(file: File) {
    try {
      this.loader.Loading.next(true);
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('categoryID', this.categoryAction.categoriesID.toString());
      formData.append('userID', this.userIDSelected.toString());
      formData.append('time', this.strTimeUpload);
      this.authService.sendFile(formData).subscribe(result => {
        this.snackBar.open(result.item1, null, { duration: 3000 });
        //this.helper.getAllTimeSplitFile();
        this.loader.Loading.next(false);
      });
    } catch (e) {
      console.log(e);
      this.loader.Loading.next(false);
    }
    //this.helper.getAllTimeSplitFile();
  }

  // Action click user item
  actionClickUser(user: User) {
    this.authService.setUserSelected(user);
    this.userIDSelected = user.id;
    if (this.typeOpenScreen == 0) {
      this.viewUserDetail(user.id);
      // view user detail
    }
    else if (this.typeOpenScreen == 4) {
      // view history
      this.viewHistory(user.id);
    }
    else if (this.typeOpenScreen == 5) {
      // send file
      this.sendFile(user.id);
    }
  }

  // Action click button upload
  async sendFile(userId: number) {
    this.isDuplicateFile = false;
    this.topNav.userIdSelected.next(userId);
    this.userIDSelected = userId;
    if (this.categoryAction && this.categoryAction.isShowCalendar) {
      this.topNav.typeOpenCompanyPopup.next(this.categoryAction.categoriesID);
      var dialogRefCalendar = this.dialog.open(CalendarPopup);
      dialogRefCalendar.afterClosed().subscribe(data => {
        if (data != null && data != undefined) {
          const fileData = data as File;
          this.topNav.timeSelect.subscribe(value => { this.strTimeUpload = value; });
          // confirm send file
          this.upload(fileData);
          //let dialogRef = this.dialog.open(ConfirmFilePopup, {
          //  data: fileData.name
          //});
          //dialogRef.afterClosed().subscribe(actionNumber => {
          //  if (actionNumber == 1) {
          //    this.upload(fileData);
          //  }
          //});
        }
      })
    }
    else {
      // todo check time
      let timeSelected = new Date();
      this.strTimeUpload = this.helper.getTime(timeSelected);
      let strTimeSplit = this.helper.getTimeSplit(this.strTimeUpload);
      let isValidTime = await this.helper.timeValid(strTimeSplit, this.categoryAction.categoriesID.toString(), this.categoryAction.typeSend, this.userIDSelected, '', true);
      if (!isValidTime) {
        let mess = this.helper.messConfirmDuplicateTime(this.categoryAction.categoriesID.toString(), strTimeSplit);
        if (confirm(mess)) {
          this.isDuplicateFile = true;
          this.openExplorer();
        }
      }
      else {
        this.openExplorer();
      }
    }
  }

  //validateFile(fileName: string, time: string) {
  //  let result = 0;
  //  let isPdf = this.helper.isPDF(fileName)
  //  if (!isPdf) {
  //    // unvalid type
  //    result = 1;
  //  }
  //  let isValidTime = this.helper.timeValid(time, this.categoryAction.categoriesID.toString(), this.categoryAction.typeSend, this.userIDSelected, '', true);
  //  if (!isValidTime) {
  //    // unvalid time
  //    result = 2;
  //  }
  //  return result;
  //}

  // open explorer
  openExplorer() {
    this.fileUploadButton.nativeElement.accept = this.categoryAction.typeAccept;
    this.fileUploadButton.nativeElement.value = "";
    this.fileUploadButton.nativeElement.click();
  }

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
