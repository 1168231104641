import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-select-file-local',
  templateUrl: './select-file-local.component.html',
  styleUrls: ['./select-file-local.component.css']
})
export class SelectFileLocalDialog implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SelectFileLocalDialog>) {
  }

  ngOnInit() {
  }

  onFileSelectLocal(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      this.dialogRef.close(fileToRead);
    }
  }
}


