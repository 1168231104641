import { Component, OnInit, ViewChild, NgZone, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WeeklyReport } from '../../../../_models/WeeklyReport';
import { WeeklyReportService } from '../../../../_services/weekly-report.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DeviceService } from '../../../../_services/device.service';

@Component({
  selector: 'app-weekly-create',
  templateUrl: './weekly-create.component.html',
  styleUrls: ['./weekly-create.component.css']
})
export class WeeklyCreateDialog implements OnInit {

  isMobile: boolean

  id: number
  reportDate: Date
  form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<WeeklyCreateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: WeeklyReport,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private apiService: WeeklyReportService,
    private loader: LoaderService,
    device: DeviceService,
  ) {
    device.IsMobile.subscribe(value => this.isMobile = value)
    dialogRef.disableClose = true
    this.reportDate = data.date
    this.id = data.report && data.report.id
  }

  GetWorkDetails(data: any): FormGroup[] {
    var workDetails: FormGroup[] = []
    if (data) {
      data.reportContent.workDetails.forEach((v, i) => {
        if (v.date.getDay() == 0 || v.date.getDay() == 6) {
          workDetails.push(this.fb.group({
            date: [v.date],
            dateValue: [v.dateValue],
            actual: [v.actual, [Validators.maxLength(400)]],
            required: false,
          }))
        } else {
          workDetails.push(this.fb.group({
            date: [v.date],
            dateValue: [v.dateValue],
            actual: [v.actual, [Validators.required, Validators.maxLength(400)]],
            required: true,
          }))
        }
      })
    }
    return workDetails
  }

  ngOnInit() {
    this.loader.Forward(this.apiService.GetDetail(this.id, this.data.dateValue)).subscribe(result => {
      if (result.status === 200) {
        var data = result.data
        data && data.reportContent.workDetails.map(x => x.date = new Date(x.dateValue.year, x.dateValue.month-1, x.dateValue.day))
        this.form = this.fb.group({
          projectName: [data && data.reportContent.projectName, [Validators.required, Validators.maxLength(80)]],
          fullName: [data.reportContent.fullName, [Validators.required, Validators.maxLength(80)]],
          reportUserName: [data && data.reportContent.reportUserName, [Validators.required, Validators.maxLength(80)]],
          responsibilityUserName: [data && data.reportContent.responsibilityUserName, [Validators.required, Validators.maxLength(80)]],

          workSituation: [data && data.reportContent.workSituation, [Validators.required, Validators.maxLength(400)]],
          meetingItem: [data && data.reportContent.meetingItem, [Validators.maxLength(400)]],
          requestItem: [data && data.reportContent.requestItem, [Validators.maxLength(400)]],
          futurePlan: [data && data.reportContent.futurePlan, [Validators.required, Validators.maxLength(400)]],

          workDetails: this.fb.array(this.GetWorkDetails(data))
        })
      }
    })
  }
  get workDetails() { return <FormArray>this.form.get('workDetails') }

  @ViewChild('cfcAutosize', { static: true })
  contentFCAutosize: CdkTextareaAutosize;

  resizeTextArea() {
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.contentFCAutosize && this.contentFCAutosize.resizeToFitContent(true));
  }

  OnSave() {
    var data = {
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.SaveReport(data, this.id, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }

  OnSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return
    var data = {
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.SubmitReport(data, this.id, this.reportDate)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }

  OnReSubmit() {
    if (!this.form.valid) return;
    var data = {
      id: this.id,
      reportContent: this.form.value,
      reportDate: this.reportDate,
    }
    this.loader.Forward(this.apiService.ReSubmitReport(data)).subscribe(result => {
      if (result.status === 200) {
        this.dialogRef.close({ isSaveSuccess: true })
      }
    });
  }
}
