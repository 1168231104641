import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateValue } from '../_models/WeeklyReport';

@Injectable({
  providedIn: 'root'
})
export class WeeklyReportService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }

  GetDetail(id?: number, date?: DateValue) {
    if (id) {
      return this.http.get<any>(this.baseUrl + `api/weekly/detail/${id}`)
    } else {
      return this.http.get<any>(this.baseUrl + `api/weekly/detail/${date.year}/${date.month}/${date.day}`)
    }
  }

  GetReports(requestDate?: Date) {
    if (requestDate) {
      return this.http.get<any>(this.baseUrl + `api/weekly/get/${requestDate.getFullYear()}/${requestDate.getMonth()+1}`)
    } else {
      return this.http.get<any>(this.baseUrl + 'api/weekly/get')
    }
  }

  GetReportOfUser(userId: number, requestDate?: Date) {
    if (requestDate) {
      return this.http.get<any>(this.baseUrl + `api/weekly/get/${userId}?y=${requestDate.getFullYear()}&m=${requestDate.getMonth() + 1}`)
    } else {
      return this.http.get<any>(this.baseUrl + `api/weekly/get/${userId}`)
    }
  }

  SaveReport(data, id?: number, reportDate?: Date) {
    if (reportDate) {
      return this.http.post<any>(this.baseUrl + `api/weekly/save?id=${id || ''}&y=${reportDate.getFullYear()}&m=${reportDate.getMonth() + 1}&d=${reportDate.getDate()}`, data)
    } else {
      return this.http.post<any>(this.baseUrl + `api/weekly/save?id=${id || ''}`, data)
    }
  }

  SubmitReport(data, id?: number, reportDate?: Date) {
    if (reportDate) {
      return this.http.post<any>(this.baseUrl + `api/weekly/submit?id=${id || ''}&y=${reportDate.getFullYear()}&m=${reportDate.getMonth() + 1}&d=${reportDate.getDate()}`, data)
    } else {
      return this.http.post<any>(this.baseUrl + `api/weekly/submit?id=${id || ''}`, data)
    }
  }

  ReSubmitReport(data) {
    return this.http.post<any>(this.baseUrl + 'api/weekly/resubmit', data)
  }

  ApproveReport(data) {
    return this.http.post<any>(this.baseUrl + 'api/weekly/approve', data)
  }

  RejectReport(data) {
    return this.http.post<any>(this.baseUrl + 'api/weekly/reject', data)
  }

  RequestReport(userId: number, requestDate: Date) {
    return this.http.get<any>(this.baseUrl + `api/weekly/request/${userId}/${requestDate.getFullYear()}/${requestDate.getMonth() + 1}/${requestDate.getDate()}`)
  }

  GetGroupWeeklyReports(id: number, requestDate?: Date) {
    if (requestDate) {
      return this.http.get<any>(this.baseUrl + `api/weekly/group/${id}/${requestDate.getFullYear()}/${requestDate.getMonth() + 1}/${requestDate.getDate()}`)
    } else {
      return this.http.get<any>(this.baseUrl + 'api/weekly/group/' + id)
    }
  }
  ExportReport(id: number) {
    return this.http.get(this.baseUrl + `api/weekly/download/${id}`, {
      responseType: 'blob',
    })
  }
}
