import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseResult } from '../_models/BaseResult';
import { MatSnackBar } from '@angular/material';
import { DeviceService } from './device.service';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    
  constructor(
    private snackBar: MatSnackBar,
    private device: DeviceService,
  ) { }

  public Loading = new BehaviorSubject<boolean>(false)
  public Error = new BehaviorSubject<{ error: boolean, message?: string }>({ error: false })
  public Subscriptions: Subscription[] = []

  public Forward(observable: Observable<BaseResult>, isLoading: boolean = true, isError: boolean = false): Observable<BaseResult> {
    return new Observable<BaseResult>(observer => {
      if (isLoading) this.Loading.next(true);

      var subscription = observable.subscribe(
        result => {
          if (isLoading) this.Loading.next(false);
          if (result.status !== 200 && isError) {
            this.Error.next({ error: true, message: result.message })
            observer.next(result)
          } else if (result.status !== 200) {
            observer.next(result)
            if (result.message) {
              this.snackBar.open(result.message, '', { duration: 5000, verticalPosition: this.device.IsMobile.value ? "top" : "bottom" })
            }
          } else {
            observer.next(result)
            if (result.message) {
              this.snackBar.open(result.message, '', { duration: 2000, verticalPosition: this.device.IsMobile.value ? "top" : "bottom" })
            }
          }
        },
        err => {
          if (isLoading) this.Loading.next(false);
          if (isError) {
            this.Error.next({ error: true, message: err.message })
            observer.next(err as BaseResult)
          }
          else {
            this.snackBar.open(err.message || 'エラーが発生しました！', '', { duration: 5000, verticalPosition: this.device.IsMobile.value ? "top" : "bottom" })
            observer.next(err as BaseResult)
          }
        },
        () => observer.complete()
      );

      this.Subscriptions.push(subscription);
      

      return () => {
        subscription.unsubscribe();
        var idx = this.Subscriptions.findIndex(s => s == subscription);
        if (idx >= 0) this.Subscriptions.splice(idx, 1);
      }
    })
  }
}
