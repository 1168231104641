import { Component, OnInit } from '@angular/core'
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material'
import { Salary } from '../../../_models/Salary'
import { TopNavService } from '../../../_services/route.service'
import { AuthService } from '../../../_services/auth.service'
import { LoaderService } from '../../../_services/loader.service'
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router'
import { PreviewFilePopup } from '../../common/popups/preview-file/preview-file.component'
import { ConfirmPopup } from '../../common/popups/confirm-popup/confirm-popup.component'
import { HelperService } from '../../../_services/helper.service'

@Component({
  selector: 'app-salary-detail',
  templateUrl: './salary-detail.component.html',
  styles: ['.full-width-input {width: 100%}']
})
export class SalaryAndBonusComponent implements OnInit {

  ScreenName: string = '給与明細配布';
  Salarys: Salary[] = [];
  SalarysSuccess: Salary[] = [];
  SalarysError: Salary[] = [];
  //columnIds = ['pageNum', 'employeeCode', 'name', 'email', 'status', 'error', 'isRead', 'action'];
  columnIds = ['employeeCode', 'name', 'selectedDate', 'status', 'error', 'isRead', 'action'];
  dataSource: MatTableDataSource<Salary>;
  strSuccess: string = '';
  strError: string = '';
  companyCd: string;
  time: string;
  isPublish: string;
  fileType: string;
  isShowBtn: boolean = false;

  constructor(
    private router: Router,
    private topNav: TopNavService,
    private loader: LoaderService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private helper: HelperService
  ) {
    this.dataSource = new MatTableDataSource<Salary>();
  }

  ngOnInit() {
    try {
      this.companyCd = this.activatedRoute.snapshot.paramMap.get('com');
      this.time = this.activatedRoute.snapshot.paramMap.get('time');
      this.fileType = this.activatedRoute.snapshot.paramMap.get('type');
      this.isPublish = this.activatedRoute.snapshot.paramMap.get('isPublish');
      if (this.fileType == "2") {
        this.ScreenName = '賞与明細配布';
      }
      else if (this.fileType == "6") {
        this.ScreenName = "配布ファイル管理";
      }
      this.getDataDbTemp(this.fileType);
    } catch (e) {
    }
  }

  getDataDbTemp(type: string) {
    this.loader.Loading.next(true);
    try {
      this.authService.GetAllSalaryByType(type, this.companyCd, this.time, this.isPublish).subscribe(data => {
        if (data != null) {
          this.Salarys = data;
          //for (var item = 0; item < this.Salarys.length; item++) {
          //  if (this.Salarys[item].status === 0) {
          //    this.SalarysSuccess.push(this.Salarys[item]);
          //  }
          //  else {
          //    this.SalarysError.push(this.Salarys[item]);
          //  }
          //}
          //if (this.SalarysError != null && this.SalarysError.length > 0) {
          //  this.openErrorScreen(false);
          //  this.strError = '異常：' + this.SalarysError.length;
          //}
          //else {
          //  this.openErrorScreen(true);
          //}
          //if (this.SalarysSuccess != null) {
          //  this.strSuccess = '正常：' + this.SalarysSuccess.length
          //  this.dataSource = new MatTableDataSource<Salary>(this.SalarysSuccess);
          //}
          this.dataSource = new MatTableDataSource<Salary>(this.Salarys);
          this.strSuccess = '正常：' + this.Salarys.filter(s => s.status == 0).length
          this.strError = '異常：' + this.Salarys.filter(s => s.status != 0).length
          this.isShowBtn = this.Salarys.filter(s => s.status == 0).length > 0
        }
        //else {
        //  this.openErrorScreen(true);
        //}
        this.loader.Loading.next(false);
      })
    } catch {
      this.loader.Loading.next(false);
    };
  }

  async publishSalaryAndBonus() {
    var fileType = this.fileType == "1" ? "給与" : "賞与"
    var timeCheck = this.time.substr(4, 2) + this.time.substr(0, 4)
    var messageDuplicate = ""
    if (!(await this.helper.timeValid(timeCheck, this.fileType.toString(), 2, null, this.companyCd, true))) {
      messageDuplicate = `\n\n※警告：「${fileType}${this.time.substr(0, 4)}年${this.time.substr(4, 2)}月」ファイルはすでに配布しました。配布すれば更新します。`
    }
    let dialogRef = this.dialog.open(ConfirmPopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: {
        title: "配布確認",
        message: `上記の正常の${fileType}を配布します。\nよろしいでしょうか。${messageDuplicate}`,
        btnPrimary: "はい",
        btnWarn: "いいえ"
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != null && data.btn == "btnPrimary") {
        this.loader.Loading.next(true);
        this.authService.PublishSalaryAndBonus(this.fileType, this.companyCd, this.time).subscribe(data => {
          if (data as number > 0) {
            this.isPublish = "1";
            this.loader.Loading.next(false);
            this.snackBar.open('正常に配布しました。', null, { duration: 3000 })
            this.router.navigateByUrl(`/admin/salary/${this.companyCd}/${this.time}/${this.fileType}/1`)
          }
        }, error => {
            this.loader.Loading.next(false);
            this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
        });
      }
    });
  }


  deleteFile(salary: Salary) {
    let dialogRef = this.dialog.open(ConfirmPopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: {
        title: this.isPublish == '1' ? "ファイル削除" : "ファイル破棄",
        message: `${salary.name ? `『${salary.name}』氏の` : ""}『${salary.nameFile}』を${this.isPublish == '1' ? "削除" : "破棄"}します。よろしいですか。`,
        btnPrimary: "はい",
        btnWarn: "いいえ"
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null && data.btn == "btnPrimary") {
        this.loader.Loading.next(true);
        this.authService.deleteFile(salary.salaryID).subscribe(result => {
          if (result) {
            var rmIdx = this.Salarys.findIndex(s => s.salaryID == salary.salaryID);
            this.Salarys.splice(rmIdx, 1);
            this.dataSource = new MatTableDataSource<Salary>(this.Salarys);
            this.loader.Loading.next(false);
            this.snackBar.open((this.isPublish == "1" ? "ファイル削除" : "ファイル破棄") + 'しました。', null, { duration: 3000 })
          }
          else {
            this.loader.Loading.next(false);
            this.snackBar.open((this.isPublish == "1" ? "ファイル削除" : "ファイル破棄") + '失敗しました。', null, { duration: 3000 })
          }
        }, error => {
          this.loader.Loading.next(false);
          this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
        });
      }
    });
  }

  deleteAllFile() {
    let dialogRef = this.dialog.open(ConfirmPopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: {
        title: "ファイル破棄",
        message: `全てファイルを破棄します。よろしいですか。`,
        btnPrimary: "はい",
        btnWarn: "いいえ"
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data != null && data.btn == "btnPrimary") {
        this.loader.Loading.next(true);
        this.authService.deleteAllFile(this.companyCd, this.fileType, this.time).subscribe(result => {
          if (result) {
            this.snackBar.open("全てファイル破棄しました。", null, { duration: 3000 })
            this.loader.Loading.next(false);
            this.router.navigateByUrl("/admin/salary/list")
          }
          else {
            this.loader.Loading.next(false);
            this.snackBar.open("ファイル破棄失敗しました。", null, { duration: 3000 })
          }
        }, error => {
          this.loader.Loading.next(false);
          this.snackBar.open('エラーが発生しました。', null, { duration: 3000 })
        });
      }
    });
  }

  openErrorScreen(disable: boolean) {
    let control = document.getElementById('linkErrorScreen');
    if (control != null) {
      if (disable) {
        control.style.pointerEvents = 'none';
      }
      else {
        control.style.pointerEvents = 'auto';
      }
    }
  }

  getData() {
    this.topNav.dataTransfer.subscribe(data => {
      this.Salarys = data
    });
    for (var item = 0; item < this.Salarys.length; item++) {
      if (this.Salarys[item].status === 0) {
        this.SalarysSuccess.push(this.Salarys[item]);
      }
      else {
        this.SalarysError.push(this.Salarys[item]);
      }
    }
    this.getDataTemp();
    this.setDataCache();
    this.strError = this.SalarysError.length + ' error';
    this.strSuccess = this.SalarysSuccess.length + ' success'
    this.dataSource = new MatTableDataSource<Salary>(this.SalarysSuccess);
  }

  setDataCache() {
    this.topNav.lstSalaryError.next(this.SalarysError);
    this.topNav.dataTransfer.next(null);
    this.topNav.dataTransfer.next(this.SalarysSuccess);
    this.topNav.lstSalarySucc.next(null);
  }

  getDataTemp() {
    this.topNav.lstSalarySucc.subscribe(data => {
      if (data) {
        for (var item = 0; item < data.lenght; item++) {
          var salary = data[item] as Salary;
          this.SalarysSuccess.push(salary);
        }
      }
    })
  }

  viewFile(data: Salary) {
    this.topNav.linkFile.next(data.url);
    this.topNav.dataTransfer.next(data);
    this.loader.Loading.next(true);
    let dialogRef = this.dialog.open(PreviewFilePopup, {
      panelClass: ['no-pad-dialog', 'pc'],
      data: { isPublish: this.isPublish, isConfirm: data.isConfirm }
    });
    //this.updateStatus(data);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.setStatusOpenFile('0');
        this.loader.Loading.next(true);
        this.authService.updateSalaryInfo(result as string, data.salaryID, this.companyCd).toPromise()
          .then(resultUpdate => {
            if (resultUpdate) {
              this.snackBar.open('社員コードを更新しました。', null, { duration: 3000 })
              this.getDataDbTemp(this.fileType);
            }
            else {
              this.snackBar.open('社員コードの更新が失敗しました。', null, { duration: 3000 })
            }
            this.loader.Loading.next(false);
          })
          .catch(() => {
            this.loader.Loading.next(false);
          })
      }
      else {
        this.loader.Loading.next(false);
        this.authService.setStatusOpenFile('0');
      }
    });
  }

  getErrorMessage(errorCd: number) {
    if (errorCd == 1) {
      return "社員番号が発見できません"
    } else if (errorCd == 2) {
      return "社員番号に応じる社員が見つかりません"
    } else if (errorCd == 3) {
      return "ファイルがアップロードできません"
    } else {
      return ""
    }
  }

  //getIsRead(isRead: boolean) {
  //  if (isRead) {
  //    return ""
  //  } else {
  //    return ""
  //  }
  //}

  toTop() {
    this.topNav.typeOpenScreenUser.next(0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.router.navigateByUrl("/admin");
  }
}
