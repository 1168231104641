import { RouterModule, Routes } from "@angular/router";
import { MainLayoutComponent } from '../_layout/admin/main-layout/main-layout.component';
import { UserListComponent } from 'src/app/_component/admin/user-list/user-list.component';
import { UserDetailComponent } from 'src/app/_component/admin/user-detail/user-detail.component';
import { GroupListComponent } from 'src/app/_component/admin/group-list/group-list.component';
import { GroupAddComponent } from 'src/app/_component/admin/group-add/group-add.component';
import { LoginLayoutComponent } from '../_layout/admin/login-layout/login-layout.component';
import { LoginComponent } from 'src/app/_component/admin/login/login.component';
import { GroupDetailComponent } from 'src/app/_component/admin/group-detail/group-detail.component';
import { CreateNotifyComponent } from '../_component/admin/create-notify/create-notify.component';
import { AdminGuard } from '../_auth/admin.guard';
import { ChangePasswordComponent } from '../_component/admin/change-password/change-password.component';
import { MyGroupComponent } from '../_component/user/my-group/my-group.component';
import { MyDailyListComponent } from '../_component/user/my-daily-list/my-daily-list.component';
import { MyWeeklyListComponent } from '../_component/user/my-weekly-list/my-weekly-list.component';
import { UserGroupDetailComponent } from '../_component/user/user-group-detail/user-group-detail.component';
import { NotificationComponent } from '../_component/user/notification/notification.component';
// tien.tranvan
import { AssignmentIndComponent } from '../_component/admin/assignment-ind/assignment-ind.component';
import { ImportUsersComponent } from '../_component/admin/import-usres/import-users.component';
import { SalaryAndBonusComponent } from '../_component/admin/salary-detail/salary-detail.component';
import { UserUpdateComponent } from '../_component/admin/user-update/user-update.component';
import { ListSalaryClientComponent } from '../_component/user/salary-list-client/salary-list-client.component';
import { SalaryListComponent } from '../_component/admin/salary-list/salary-list.component';
import { TaxComponent } from '../_component/admin/tax-detail/tax-detail.component';

const adminRoutes: Routes =
  [
    {
      path: 'admin', component: MainLayoutComponent, canActivate: [AdminGuard], children: [
        { path: '', redirectTo: 'user', pathMatch: 'full' },
        { path: 'user', component: UserListComponent },
        { path: 'user/:userId', component: UserDetailComponent },
        { path: 'group', component: GroupListComponent },
        { path: 'group/create', component: GroupAddComponent },
        { path: 'group/detail/:groupId', component: GroupDetailComponent },
        { path: 'group/edit/:groupId', component: GroupAddComponent },
        { path: 'notice', component: CreateNotifyComponent },
        { path: 'change-password', component: ChangePasswordComponent },
        {
          path: 'report', children: [
            { path: '', redirectTo: 'group', pathMatch: 'full' },
            { path: 'daily', component: MyDailyListComponent },
            { path: 'weekly', component: MyWeeklyListComponent },
            { path: 'notification', component: NotificationComponent },
            { path: 'group', component: MyGroupComponent },
            { path: 'group/:groupId', component: UserGroupDetailComponent },
          ]
        },
        // tien.tranvan
        { path: 'assignment-ind', component: AssignmentIndComponent },
        { path: 'import', component: ImportUsersComponent, data: null },
        { path: 'salary/list', component: SalaryListComponent, data: null },
        { path: 'salary/:com/:time/:type/:isPublish', component: SalaryAndBonusComponent, data: null },
        { path: 'user/update/:userId', component: UserUpdateComponent },
        { path: 'update', component: UserUpdateComponent },
        { path: 'files', component: ListSalaryClientComponent },

        { path: 'tax/:time/:type/:isPublish', component: TaxComponent, data: null },
      ]
    },
    {
      path: 'admin', component: LoginLayoutComponent, children: [
        { path: 'login', component: LoginComponent },
      ]
    },
  ]
export const AdminRoutes = RouterModule.forRoot(adminRoutes);
