import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../calendar-popup/custom.appdateadapter';
import { TopNavService } from '../../../../_services/route.service';
import { AuthService } from '../../../../_services/auth.service';
import { TimeSplitFile } from '../../../../_models/TimeSplitFile';
import { MatSnackBar } from '@angular/material';
import { HelperService } from '../../../../_services/helper.service';
import { CategoriesAction } from '../../../../_models/CategoriesAction';
import { ConfirmFilePopup } from '../confirm-file/confirm-file.component';

@Component({
  selector: 'app-calendar-popup',
  templateUrl: './calendar-popup.component.html',
  styleUrls: ['./calendar-popup.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class CalendarPopup implements OnInit {
  @ViewChild('datePicker', { static: false }) picker: MatDatepicker<Date>;
  @ViewChild('fileUpload', { static: false }) fileUpdateButton: ElementRef;
  events: string[] = [];
  strTime: string;
  strTimeSplit: string;
  isErr: boolean = false;
  fileType: string = '1';
  textButton: string = '給与明細書のPDFファイルアップロード';
  TimeSplitFiles: TimeSplitFile[] = [];
  categoryAction: CategoriesAction;
  userIDSelected: number = 0;
  isDuplicateFile: boolean;
  companyCode: string;
  titlePopup: string;

  constructor(
    public dialog: MatDialog,
    private topNav: TopNavService,
    private snackBar: MatSnackBar,
    private helper: HelperService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CalendarPopup>,
  ) {
  }

  ngOnInit() {
    this.titlePopup = '支払日';
    this.topNav.categories.subscribe(value => {
      this.categoryAction = value;
    });
    this.companyCode = this.authService.getCompanyCode();
    this.topNav.userIdSelected.subscribe(value => { this.userIDSelected = value });
    this.topNav.typeOpenCompanyPopup.subscribe(fileType => {
      this.fileType = fileType.toString();
      switch (this.fileType) {
        case '2':
          this.textButton = '賞与明細書のPDFファイルアップロード';
          break;
        case '5':
          this.textButton = '源泉徴収票のPDFファイルアップロード';
          this.titlePopup = '配布ファイルと日付選択';
          break;
        case '6':
          this.textButton = 'PDFファイルアップロード';
          this.titlePopup = '配布ファイルと日付選択';
          break;
      }
    });
    //this.helper.getAllTimeSplitFile();
  }

  onFileSelectLocal(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      let fileToRead;
      let isPdf = true;
      let fileName = "";
      if (this.fileType == '6') {
        fileToRead = files;
        const _helper = this.helper;
        let filesName = [];
        Array.from(files).forEach(function (file) {
          if (!_helper.isPDF(file.name)) {
            isPdf = false;
          }

          filesName.push(file.name);
        });

        fileName = filesName.join(", ");
      }
      else {
        fileToRead = files[0];
        isPdf = this.helper.isPDF(fileToRead.name);
        fileName = fileToRead.name;
      }
      this.getTimeSelect();
      if (isPdf) {
        if (this.isErr == false) {
          if (!this.isDuplicateFile) {
            // confirm send file
            let dialogRef = this.dialog.open(ConfirmFilePopup, {
              data: fileName
            });
            dialogRef.afterClosed().subscribe(actionNumber => {
              if (actionNumber == 1) {
                this.topNav.timeSelect.next(this.strTime);
                this.dialogRef.close(fileToRead);
              }
            });
          }
          else {
            this.topNav.timeSelect.next(this.strTime);
            this.dialogRef.close(fileToRead);
          }
        }
      }
      else {
        this.snackBar.open('フォーマットファイルが無効です。', null, { duration: 3000 })
      }
    }
  }

  getTimeSelect() {
    if (this.picker._selected === null) {
      this.isErr = true;
    }
    else {
      this.isErr = false;
      let timeSelected = this.picker._selected;
      this.strTime = this.helper.getTime(timeSelected);
      this.strTimeSplit = this.helper.getTimeSplit(this.strTime);
    }
  }

  async uploadFile() {
    this.isDuplicateFile = false;
    this.fileUpdateButton.nativeElement.value = "";
    this.getTimeSelect();
    if (!this.isErr) {
      if (!(await this.helper.timeValid(this.strTimeSplit, this.fileType, this.categoryAction.typeSend, this.userIDSelected, this.companyCode, false))) {
        let mess = this.helper.messConfirmDuplicateTime(this.fileType, this.strTimeSplit);
        if (confirm(mess)) {
          this.isDuplicateFile = true;
          this.fileUpdateButton.nativeElement.click();
        }
      }
      else {
        this.fileUpdateButton.nativeElement.click();
      }
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    if (this.events.values) {
      this.isErr = false;
    }
  }
}

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};
