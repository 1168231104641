import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupRole } from 'src/app/_models/GroupRole';
import { Member } from 'src/app/_models/Member';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.css']
})
export class EditMemberDialog implements OnInit {

  availableUsers: Member[]
  inRoleMembers: Member[]
  groupRoleId: number
  groupRoleName: string
  memberNameFilter: string
  userNameFilter: string

  constructor(
    public dialogRef: MatDialogRef<EditMemberDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EditMemberData
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.groupRoleId = this.data.id;
    this.groupRoleName = (this.data.groupRoles.find(g => g.id === this.groupRoleId)).name;
    this.inRoleMembers = this.data.members.filter(m => m.groupRoleId === this.groupRoleId).clone();
    this.availableUsers = this.data.members.filter(m => m.groupRoleId !== this.groupRoleId).clone();
    this.availableUsers.push(...this.data.users);
  }

  RemoveMember(members) {
    members.forEach(m => {
      var member = this.inRoleMembers.splice(this.inRoleMembers.findIndex(u => u.id === m.value), 1)[0];
      member.groupRoleId = null;
      member.groupRoleName = null;
      this.availableUsers.push(member);
    })
    console.log(this.inRoleMembers);
  }

  AddMember(members) {
    members.forEach(m => {
      var user = this.availableUsers.splice(this.availableUsers.findIndex(u => u.id === m.value), 1)[0];
      user.groupRoleId = this.groupRoleId;
      user.groupRoleName = this.groupRoleName;
      this.inRoleMembers.push(user);
    })
  }

  OnSave() {
    this.availableUsers.forEach(u => {
      if (u.groupRoleId) {
        this.inRoleMembers.push(u);
      }
    });
    this.availableUsers = this.availableUsers.filter(u => !u.groupRoleId);
    this.dialogRef.close({
      isSave: true,
      data: {
        members: this.inRoleMembers,
        users: this.availableUsers
      }
    });
  }
}

export interface EditMemberData {
  id: number,
  users: Member[],
  groupRoles: GroupRole[],
  members: Member[]
}
