import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-update-file-info',
  templateUrl: './update-file-info.component.html',
  styleUrls: ['./update-file-info.component.css']
})
export class UpdateFileInfoPopup implements OnInit {
  form: FormGroup;
  formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateFileInfoPopup>,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      employeeCode: ['', [Validators.required, Validators.maxLength]]
    })
  }

  onSubmit() {
    if (this.form.valid) {
      const employeeCode = this.form.value.employeeCode;
      this.dialogRef.close(employeeCode);
    }
  }

  isFieldInvalid() {
    return (
      (!this.form.valid && this.form.touched) ||
      (this.form.untouched && this.formSubmitAttempt)
    );
  }

  onTextChange(textValue: string): void {
    this.form.patchValue({ employeeCode: textValue.replace(' ', '') });
  }
}
