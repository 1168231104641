import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthResult } from '../_models/AuthResult';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementFileService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
  }

  ChangePasswordViewFile(newPass: string) {
    const formData: FormData = new FormData();
    formData.append('newPass', btoa(newPass));
    var result = this.http.post<boolean>(this.baseUrl + 'api/file/password-file', formData);
    if (result) {
      localStorage.setItem('passViewFile', newPass);
    }
    return result;
  }

  // get all file
  GetAllFileInfo(typeGet: number, userId: number, page: number, size: number) {
    const formData: FormData = new FormData();
    formData.append('typeGet', typeGet.toString());
    if (userId > 0) {
      formData.append('userId', userId.toString());
    }
    else {
      formData.append('userId', this.authService.GetCurrentUserId().toString());
    }
    formData.append('page', page.toString());
    formData.append('size', size.toString());
    var result = this.http.post<any>(this.baseUrl + 'api/account/salary', formData);
    return result;
  }
}
