import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/_services/loader.service';
import { DeviceService } from '../../../_services/device.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  @Input() isUserMode: boolean

  message: string
  isError: boolean
  isMobile: boolean
  @Output() onReload: EventEmitter<any> = new EventEmitter();

  constructor(
    private location: Location,
    private loader: LoaderService,
    private device: DeviceService,
  ) { }

  ngOnInit() {
    this.loader.Error.subscribe(value => {
      this.isError = value.error
      this.message = value.message || 'エラーが発生しました！'
    })
    this.device.IsMobile.subscribe(value => this.isMobile = value)
  }

  Reload() {
    this.onReload.emit('onReload')
  }
  Back() {
    this.isError = false
    this.loader.Error.next({ error: false })
    this.location.back()
  }
}
