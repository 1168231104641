import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../_models/User';

@Pipe({
  name: 'nameFilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(users: User[], name: string): User[] {
    if (!users || !name) return users;
    //users.map(u => u.hidden = !(u.name.toLowerCase().indexOf(name.toLowerCase()) >= 0));
    //return users;
    return users.filter(u => u.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
}
