import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TopNavService } from '../../../../_services/route.service';

@Component({
  selector: 'app-confirm-file',
  templateUrl: './confirm-file.component.html',
  styleUrls: ['./confirm-file.component.css']
})
export class ConfirmFilePopup implements OnInit {
  messConfirm: string = "";
  titleAccept: string = "";
  titlePopup: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private nameFile: string,
    private topNav: TopNavService,
    private dialogRef: MatDialogRef<ConfirmFilePopup>,
  ) {
  }

  ngOnInit() {
    this.getTitle();
  }

  getTitle() {
    this.topNav.typeOpenCompanyPopup.subscribe(value => {
      if (value == 4) {
        this.titleAccept = "削除";
        this.messConfirm = '「' + this.nameFile + '」' + 'ファイルを削除しますか？';
        this.titlePopup = 'ファイル削除';
      }
      else {
        this.titleAccept = "送信";
        this.messConfirm = this.nameFile + 'を送信しますか？';
        this.titlePopup = 'ファイル送信';
      }
    });
  }

  actionClick(actionNumber: number) {
    this.dialogRef.close(actionNumber);
  }
}
