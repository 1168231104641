import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './_interceptor/auth.interceptor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppComponent } from './app.component';
import { UserListComponent } from 'src/app/_component/admin/user-list/user-list.component';
import { UserDetailComponent } from 'src/app/_component/admin/user-detail/user-detail.component';
import { GroupListComponent } from 'src/app/_component/admin/group-list/group-list.component';
import { ErrorComponent } from 'src/app/_component/common/error/error.component';
import { LoadingComponent } from 'src/app/_component/common/loading/loading.component';
import { GroupAddComponent } from 'src/app/_component/admin/group-add/group-add.component';
import { LoginComponent } from 'src/app/_component/admin/login/login.component';
import { MainLayoutComponent } from './_layout/admin/main-layout/main-layout.component';
import { LoginLayoutComponent } from './_layout/admin/login-layout/login-layout.component';
import { MaterialModule } from './_module/material/material.module';
import { EditMemberDialog } from 'src/app/_component/admin/group-add/edit-member/edit-member.component';
import { UserFilterPipe } from './_pipe/user-filter.pipe';
import { MemberFilterPipe } from './_pipe/member-filter.pipe';
import { GroupDetailComponent } from 'src/app/_component/admin/group-detail/group-detail.component';
import { UserLoginLayoutComponent } from './_layout/user/user-login-layout/user-login-layout.component';
import { UserLoginComponent } from 'src/app/_component/user/login/login.component';

import { AdminRoutes } from './_router/admin.router';
import { UserRoutes } from './_router/user.router';
import { UserMainLayoutComponent } from './_layout/user/user-main-layout/user-main-layout.component';
import { MyDailyListComponent } from './_component/user/my-daily-list/my-daily-list.component';
import { MyWeeklyListComponent } from './_component/user/my-weekly-list/my-weekly-list.component';
import { MyInfoComponent } from './_component/user/my-info/my-info.component';
import { DailyCreateDialog } from './_component/user/my-daily-list/daily-create/daily-create.component';
import { JpDatePipe } from './_pipe/jp-date.pipe';
import { NotificationComponent } from './_component/user/notification/notification.component';
import { MyGroupComponent } from './_component/user/my-group/my-group.component';
import { UserGroupDetailComponent } from './_component/user/user-group-detail/user-group-detail.component';
import { DailyGroupComponent } from './_component/user/daily-group/daily-group.component';
import { ReviewDailyDialog } from './_component/user/daily-group/review-daily/review-daily.component';
import { NoticeDetailComponent, NoticeDetailDialog } from './_component/user/notification/notice-detail/notice-detail.component';
import { TimeAgoPipe } from './_pipe/time-ago.pipe';
import { ScrollDirective } from './_directive/scroll.directive';
import { DeleteDialog } from './_component/admin/group-list/delete-dialog/delete-dialog.component';
import { CreateNotifyComponent } from './_component/admin/create-notify/create-notify.component';
import { ChangePasswordComponent } from './_component/admin/change-password/change-password.component';
import { WeeklyCreateDialog } from './_component/user/my-weekly-list/weekly-create/weekly-create.component';
import { WeeklyGroupComponent } from './_component/user/weekly-group/weekly-group.component';
import { ReviewWeeklyDialog } from './_component/user/weekly-group/review-weekly/review-weekly.component';
import { ViewDailyUserComponent } from './_component/user/view-daily-user/view-daily-user.component';
import { ViewWeeklyUserComponent } from './_component/user/view-weekly-user/view-weekly-user.component';
// tien.tranvan
import { CompanyDialog } from './_component/common/popups/company-popup/company-popup.component'
import { CalendarPopup } from './_component/common/popups/calendar-popup/calendar-popup.component'
import { AssignmentIndComponent } from './_component/admin/assignment-ind/assignment-ind.component';
import { SalaryAndBonusComponent } from './_component/admin/salary-detail/salary-detail.component';
import { TaxComponent } from './_component/admin/tax-detail/tax-detail.component';
import { ImportUsersComponent } from './_component/admin/import-usres/import-users.component';
import { SelectFileLocalDialog } from './_component/admin/select-file-local/select-file-local.component';
import { UserUpdateComponent } from './_component/admin/user-update/user-update.component';
import { PreviewFilePopup } from './_component/common/popups/preview-file/preview-file.component';
import { UpdateFileInfoPopup } from './_component/common/popups/update-file-info/update-file-info.component';
import { FileAuthPopup } from './_component/common/popups/file-auth/file-auth.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SalaryBonusComponent } from './_component/user/salary-bonus/salary-bonus.component';
import { ListSalaryClientComponent } from './_component/user/salary-list-client/salary-list-client.component';
import { ConfirmFilePopup } from './_component/common/popups/confirm-file/confirm-file.component'
import { AppDateAdapter } from './_component/common/popups/calendar-popup/custom.appdateadapter';
import { CustomDatePipe } from './_component/common/popups/calendar-popup/custom.datepipe';
import { SalaryListComponent } from './_component/admin/salary-list/salary-list.component';
import { ConfirmPopup } from './_component/common/popups/confirm-popup/confirm-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    UserListComponent,
    UserDetailComponent,
    GroupListComponent,
    ErrorComponent,
    LoadingComponent,
    GroupAddComponent,
    LoginComponent,
    MainLayoutComponent,
    LoginLayoutComponent,
    EditMemberDialog,
    UserFilterPipe,
    MemberFilterPipe,
    GroupDetailComponent,
    UserLoginLayoutComponent,
    UserLoginComponent,
    UserMainLayoutComponent,
    MyDailyListComponent,
    MyWeeklyListComponent,
    MyInfoComponent,
    DailyCreateDialog,
    JpDatePipe,
    NotificationComponent,
    MyGroupComponent,
    UserGroupDetailComponent,
    DailyGroupComponent,
    ReviewDailyDialog,
    NoticeDetailDialog,
    NoticeDetailComponent,
    TimeAgoPipe,
    ScrollDirective,
    DeleteDialog,
    CreateNotifyComponent,
    ChangePasswordComponent,
    WeeklyCreateDialog,
    WeeklyGroupComponent,
    ReviewWeeklyDialog,
    ViewDailyUserComponent,
    ViewWeeklyUserComponent,
    // tien.tranvan
    CompanyDialog,
    AssignmentIndComponent,
    CalendarPopup,
    SalaryAndBonusComponent,
    TaxComponent,
    ImportUsersComponent,
    SelectFileLocalDialog,
    UserUpdateComponent,
    PreviewFilePopup,
    UpdateFileInfoPopup,
    FileAuthPopup,
    SalaryBonusComponent,
    ListSalaryClientComponent,
    ConfirmFilePopup,
    AppDateAdapter,
    CustomDatePipe,
    SalaryListComponent,
    ConfirmPopup
  ],
  imports: [
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    AdminRoutes,
    UserRoutes,
    CKEditorModule,
    PdfViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [EditMemberDialog,
    NoticeDetailDialog, DeleteDialog,
    DailyCreateDialog, ReviewDailyDialog, 
    WeeklyCreateDialog, ReviewWeeklyDialog,
    // tien.tranvan
    CompanyDialog,
    CalendarPopup,
    SelectFileLocalDialog,
    PreviewFilePopup,
    UpdateFileInfoPopup,
    FileAuthPopup,
    ConfirmFilePopup,
    ConfirmPopup
  ]
})
export class AppModule { }
